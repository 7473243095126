import { XMarkIcon } from '@heroicons/react/20/solid'
import { cn } from '@qogita/ui/utils/cn'
import * as ToastPrimitives from '@radix-ui/react-toast'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'

const ToastProvider = ToastPrimitives.Provider

const ToastViewport = forwardRef<
  ElementRef<typeof ToastPrimitives.Viewport>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cn(
      // Pointer events none prevents this from obscuring interactive elements underneath it
      'pointer-events-none fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:right-0 sm:top-24 sm:flex-col md:max-w-[420px] md:px-6',
      className,
    )}
    {...props}
  />
))
ToastViewport.displayName = ToastPrimitives.Viewport.displayName

const Toast = forwardRef<
  ElementRef<typeof ToastPrimitives.Root>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Root>
>(({ className, ...props }, ref) => {
  return (
    <ToastPrimitives.Root
      ref={ref}
      forceMount
      className={cn(
        // Add back in pointer events for the toast itself so it is clickable
        'pointer-events-auto transition-all',
        // Handles user swiping to dismiss the toast
        'data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none',
        // Handles animating the toast in
        'data-[state=open]:animate-fade-in data-[state=open]:motion-safe:animate-slide-in-from-top',
        // Handles animating the toast out
        'data-[state=closed]:translate-x-full data-[state=closed]:opacity-0',
        'shadow-lg',
        className,
      )}
      // We hold the toast in the DOM when it's closed so exit transitions can run but that means
      // we need to hide it from screen readers when it's closed
      // The automatic 'REMOVE_TOAST' action from useToast will remove it from the DOM after a further short delay
      aria-hidden={props.open ? undefined : 'true'}
      {...props}
    />
  )
})
Toast.displayName = ToastPrimitives.Root.displayName

const ToastAction = forwardRef<
  ElementRef<typeof ToastPrimitives.Action>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>((props, ref) => <ToastPrimitives.Action ref={ref} asChild {...props} />)
ToastAction.displayName = ToastPrimitives.Action.displayName

const ToastClose = forwardRef<
  ElementRef<typeof ToastPrimitives.Close>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>((props, ref) => (
  <ToastPrimitives.Close ref={ref} {...props} asChild>
    <button className="text-gray-400 hover:text-gray-500" aria-label="Dismiss">
      <XMarkIcon className="size-6" />
    </button>
  </ToastPrimitives.Close>
))
ToastClose.displayName = ToastPrimitives.Close.displayName

const ToastTitle = forwardRef<
  ElementRef<typeof ToastPrimitives.Title>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>((props, ref) => <ToastPrimitives.Title ref={ref} {...props} />)
ToastTitle.displayName = ToastPrimitives.Title.displayName

const ToastDescription = forwardRef<
  ElementRef<typeof ToastPrimitives.Description>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>((props, ref) => <ToastPrimitives.Description ref={ref} {...props} />)
ToastDescription.displayName = ToastPrimitives.Description.displayName

type ToastProps = ComponentPropsWithoutRef<typeof Toast>

export {
  Toast,
  ToastAction,
  ToastClose,
  ToastDescription,
  type ToastProps,
  ToastProvider,
  ToastTitle,
  ToastViewport,
}

import { logError } from '@qogita/logging/browser-logger'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

export const CmsPanelErrorBoundary = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <ErrorBoundary
      FallbackComponent={() => null}
      onError={(error) => {
        logError(error)
      }}
    >
      {children}
    </ErrorBoundary>
  )
}

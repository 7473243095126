import { flip, offset, size, useFloating } from '@floating-ui/react'
import { FloatingOverlay } from '@floating-ui/react'
import {
  Combobox as ComboboxPrimitive,
  ComboboxInput as ComboboxPrimitiveInput,
  type ComboboxInputProps as ComboboxPrimitiveInputProps,
  ComboboxOption as ComboboxPrimitiveOption,
  type ComboboxOptionProps as ComboboxPrimitiveOptionProps,
  ComboboxOptions as ComboboxPrimitiveOptions,
  type ComboboxOptionsProps as ComboboxPrimitiveOptionsProps,
  type ComboboxProps as ComboboxPrimitiveProps,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import {
  createContext,
  ElementRef,
  ElementType,
  FocusEventHandler,
  forwardRef,
  Fragment,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useContext,
  useId,
  useState,
} from 'react'
import { mergeRefs } from 'react-merge-refs'

import { cn } from '../Utils/cn'

type ComboboxContext = {
  rootOptionId: string
  isStatic?: boolean
  onInputFocus: FocusEventHandler<HTMLInputElement>
  query: string
  setQuery: (query: string) => void
  floatingRefs: ReturnType<typeof useFloating>['refs']
  floatingStyles: ReturnType<typeof useFloating>['floatingStyles']
  readOnly: boolean
}

const ComboboxContext = createContext<ComboboxContext | undefined>(undefined)

function useComboboxContext() {
  const context = useContext(ComboboxContext)
  if (context === undefined) {
    throw new Error(
      'Combobox compound components must be used inside the Combobox component',
    )
  }
  return context
}

/**
 * We only alow string values rather than complex objects. This keeps consistency
 * with the radix Select component, and makes the types a lot easier to work with
 */
type ComboboxProps = Omit<
  ComboboxPrimitiveProps<string, false, ElementType>,
  'nullable' | 'multiple' | 'className'
> & {
  /**
   * We only allow the string form of className. Not the HeadlessUI className function
   * To style based on state, use the `data-` attributes https://headlessui.com/react/combobox#using-data-attributes
   */
  className?: string
  /**
   * Should the options open immediately on focus
   * This will become an official headless UI prop in the future and can be removed then
   * @default false
   */
  immediate?: boolean
  /**
   * Should the input be read only
   * The options element won't open if the input is read only and the onChange won't fire
   */
  readOnly?: boolean
  /**
   * Limits the width of the combobox options to the width of the input
   */
  fixedWidth?: boolean
  /**
   * Adds an overlay to the content outside the combobox input and options
   * Only works if `immediate` is true
   */
  backdrop?: boolean
}

/**
 * Combobox based on HeadlessUI's Combobox component
 * We use headless because Radix doesn't have a Combobox component yet
 *
 * This is more restrictive than the plain Headless component.
 *
 * It only allows string values, and doesn't allow nullable or multiple select
 *
 * @see https://headlessui.com/react/combobox
 */
const Combobox = forwardRef<
  ElementRef<typeof ComboboxPrimitive>,
  ComboboxProps
>(function Combobox(
  {
    className,
    immediate = false,
    onChange,
    readOnly = false,
    fixedWidth = false,
    backdrop = false,
    children,
    ...props
  },
  ref,
) {
  // The first part of the id for each option, so we can reference them in the blur event
  const rootOptionId = useId()

  const onInputFocus: FocusEventHandler<HTMLInputElement> = (event) => {
    if (readOnly) return
    if (!immediate) return

    // If we're using immediate, we need to manually open the options on focus
    event.target?.setSelectionRange(0, event.target.value.length)
  }

  const onValueChange = () => {
    if (readOnly) return
  }

  // The query entered into the input, can be used to filter the options
  const [query, setQuery] = useState('')

  // We use floating UI to position the options, rendered inside a portal
  // eslint-disable-next-line unicorn/prevent-abbreviations
  const { refs: floatingRefs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [
      offset(4),
      flip({ padding: 8 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
            ...(fixedWidth ? { maxWidth: `${rects.reference.width}px` } : {}),
          })
        },
        padding: 8,
      }),
    ],
  })

  return (
    <ComboboxContext.Provider
      value={{
        rootOptionId,
        onInputFocus,
        query,
        setQuery,
        floatingRefs,
        floatingStyles,
        readOnly,
      }}
    >
      <ComboboxPrimitive
        as="div"
        multiple={false}
        immediate={immediate}
        {...props}
        onChange={(value) => {
          onValueChange()
          onChange?.(value)
        }}
        className={cn('relative', className)}
        ref={ref}
      >
        {({ open, ...rest }) => (
          <>
            {backdrop ? (
              <Transition show={open} as={Fragment}>
                <TransitionChild
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <FloatingOverlay className="z-30">
                    <div className="fixed inset-0 bg-gray-900 bg-opacity-50" />
                  </FloatingOverlay>
                </TransitionChild>
              </Transition>
            ) : null}
            {typeof children === 'function'
              ? children({ open, ...rest })
              : children}
          </>
        )}
      </ComboboxPrimitive>
    </ComboboxContext.Provider>
  )
})
Combobox.displayName = ComboboxPrimitive.displayName

type ComboboxInputProps = Omit<
  ComboboxPrimitiveInputProps<'input', string>,
  'className' | 'readOnly'
> & { className?: string }
const ComboboxInput = forwardRef<
  ElementRef<typeof ComboboxPrimitiveInput>,
  ComboboxInputProps
>(function ComboboxInput(
  { className, onFocus, onBlur, onChange, ...props },
  ref,
) {
  const { onInputFocus, setQuery, floatingRefs, readOnly } =
    useComboboxContext()

  return (
    <ComboboxPrimitiveInput
      ref={mergeRefs([ref, floatingRefs.setReference])}
      {...props}
      onFocus={(event) => {
        onInputFocus(event)
        onFocus?.(event)
      }}
      onBlur={(event) => {
        onBlur?.(event)
      }}
      onChange={(event) => {
        setQuery(event.target.value)
        onChange?.(event)
      }}
      className={cn(
        'focus:ring-primary-700 aria-invalid:border-error-700 aria-invalid:focus:ring-error-700 flex h-10 w-full items-center justify-between gap-2 rounded border border-gray-300 bg-white py-2 pl-3 pr-2 placeholder:text-gray-500 read-only:cursor-not-allowed read-only:border-gray-200 read-only:bg-gray-50 read-only:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-1 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-400',
        className,
      )}
      readOnly={readOnly}
    />
  )
})
ComboboxInput.displayName = ComboboxPrimitiveInput.displayName

// We remove the unmount prop here to simplify the typescript, as it's mutually exlusive with static
// and we want to use that one to control the opening of the options
type ComboboxOptionsProps = Omit<
  ComboboxPrimitiveOptionsProps<'ul'>,
  'className' | 'static' | 'unmount' | 'children'
> & {
  className?: string
  children:
    | ReactNode
    | ((bag: {
        /** Is the combobox open - this comes from HeadlessUI */
        open: boolean
        /** The query entered into the input - this is our own render prop */
        query: string
        // We're using `any` to match the HeadlessUI types
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }) => ReactElement<any, string | JSXElementConstructor<any>>)
}

const ComboboxOptions = forwardRef<
  ElementRef<typeof ComboboxPrimitiveOptions>,
  ComboboxOptionsProps
>(function ComboboxOptions({ className, children, ...props }, ref) {
  const { query, floatingRefs, floatingStyles, readOnly } = useComboboxContext()

  // Never show the options if the input is read only
  if (readOnly) return null

  return (
    <ComboboxPrimitiveOptions
      {...props}
      ref={mergeRefs([ref, floatingRefs.setFloating])}
      style={floatingStyles}
      transition
      className={cn(
        'z-50',
        'origin-top transition-opacity duration-200 data-[closed]:opacity-0',
        'absolute mt-1 max-h-60 overflow-auto rounded bg-white py-1 text-base text-gray-900 shadow-lg ring-1 ring-gray-300 focus:outline-none',
        className,
      )}
    >
      {typeof children === 'function'
        ? ({ open }) => children({ open, query })
        : children}
    </ComboboxPrimitiveOptions>
  )
})
ComboboxOptions.displayName = ComboboxPrimitiveOptions.displayName

type ComboboxOptionProps = Omit<
  ComboboxPrimitiveOptionProps<'li', string>,
  'className' | 'id'
> & { className?: string }

const ComboboxOption = forwardRef<
  ElementRef<typeof ComboboxPrimitiveOption>,
  ComboboxOptionProps
>(function ComboboxOption({ className, ...props }, ref) {
  const { rootOptionId } = useComboboxContext()
  const idSuffix = useId()

  const id = `${rootOptionId}-${idSuffix}`

  return (
    <ComboboxPrimitiveOption
      ref={ref}
      {...props}
      id={id}
      className={cn(
        'ui-active:bg-primary-700 ui-active:text-white relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2 pr-8 text-sm outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
        className,
      )}
    />
  )
})
ComboboxOption.displayName = ComboboxPrimitiveOption.displayName

/**
 * Use this to indicate that no results are found for a particular query
 */
const ComboboxEmptyValue = forwardRef<
  ElementRef<typeof ComboboxPrimitiveOption>,
  Omit<ComboboxOptionProps, 'value' | 'disabled'>
>(function ComboboxEmptyValue(props, ref) {
  return <ComboboxOption ref={ref} disabled value="" {...props} />
})
ComboboxEmptyValue.displayName = 'ComboboxEmptyValue'

export {
  Combobox,
  ComboboxEmptyValue,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
}

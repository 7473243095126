import { logError } from '@qogita/logging/browser-logger'
import { HTTPError } from 'ky'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { Layout } from '#components/Layout'
import { NextAnchor } from '#components/NextAnchor'

type Props = { children: ReactNode }
export function RootErrorBoundary({ children }: Props) {
  const { pathname } = useRouter()
  return (
    <ErrorBoundary
      onError={(error) => {
        if (error instanceof HTTPError) {
          logError(error, {
            status: error.response.status,
            url: error.request.url,
          })
          return
        }

        logError(
          new Error(`RootErrorBoundary: ${error.message}`, { cause: error }),
        )
      }}
      FallbackComponent={FallbackComponent}
      key={pathname}
    >
      {children}
    </ErrorBoundary>
  )
}

function FallbackComponent() {
  return (
    <Layout pageBlok={null} title="Something went wrong" description="">
      <div className="container mx-auto px-2 sm:px-4">
        <div className="rounded bg-white p-4 shadow">
          <h1 className="mb-4 font-bold">Something went wrong</h1>
          <p>
            We are working to resolve this. Please{' '}
            <NextAnchor color="primary" size="inline" href="/contact/">
              contact us
            </NextAnchor>{' '}
            if it persists.
          </p>
        </div>
      </div>
    </Layout>
  )
}

import { CanaryClient, GetCartLinesSearchParams } from '@qogita/canary-client'
import { useCanaryClient } from '@qogita/canary-client/provider'
import { PatchedCheckoutRequest } from '@qogita/canary-types'
import { logError } from '@qogita/logging/browser-logger'
import {
  infiniteQueryOptions,
  matchQuery,
  queryOptions,
  useIsMutating,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { useRouter } from 'next/router'

import { useTrackEvent } from '#lib/report/tracking'

import { getCartQueries } from './cart-queries'
import { getOrderQueries } from './order-queries'
import { getUserQueries } from './user-queries'
import { replaceUndefinedValuesWithNull } from './utils'

export function getCheckoutQueries(canaryClient: CanaryClient) {
  const queries = {
    all: () => ['checkouts'] as const,
    allDetails: () => [...queries.all(), 'detail'] as const,
    detail: () =>
      queryOptions({
        queryKey: [...queries.allDetails()] as const,
        queryFn: () => canaryClient.getCheckout(),
      }),
    allLines: () => [...queries.detail().queryKey, 'lines'] as const,
    linesList: (params: GetCartLinesSearchParams = {}) =>
      queryOptions({
        queryKey: [
          ...queries.allLines(),
          'list',
          replaceUndefinedValuesWithNull(params),
        ] as const,
        queryFn: () => canaryClient.getCheckoutLines(params),
      }),
    linesInfiniteList: (params: Omit<GetCartLinesSearchParams, 'page'> = {}) =>
      infiniteQueryOptions({
        queryKey: [...queries.linesList(params).queryKey, 'infinite'],
        queryFn: ({ pageParam }) =>
          canaryClient.getCheckoutLines({ ...params, page: pageParam }),
        initialPageParam: 1,
        getNextPageParam: (lastPage) => {
          if (!lastPage.next) return null
          try {
            // Canary gives us the full next url, we don't care about that, but we can parse the page param from it
            const nextPageCanaryUrl = new URL(lastPage.next)
            const nextPageParameter = Number(
              nextPageCanaryUrl.searchParams.get('page'),
            )
            if (isNaN(nextPageParameter)) {
              return null
            }
            return nextPageParameter
          } catch {
            logError('Failed to parse next page param from Canary URL')
            return null
          }
        },
      }),
  }
  return queries
}

/**
 * Use to validate the checkout on the cart page before loading the checkout page
 * It'll clear out any invalid selections from the checkout model
 *
 * For example, if a previously selected address is now invalid because their VAT registration has changed
 *  */
export function useValidateCheckoutMutation() {
  const canaryClient = useCanaryClient()
  const checkoutQueries = getCheckoutQueries(canaryClient)
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [...checkoutQueries.detail().queryKey, 'validate'] as const,
    mutationFn: () => canaryClient.validateCheckout(),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export function useCompleteCheckoutMutation(qid: string) {
  const canaryClient = useCanaryClient()
  const checkoutQueries = getCheckoutQueries(canaryClient)
  const cartQueries = getCartQueries(canaryClient)
  const orderQueries = getOrderQueries(canaryClient)
  const userQueries = getUserQueries(canaryClient)
  const queryClient = useQueryClient()
  const router = useRouter()
  const { trackCheckoutCompleted, trackFirstCheckoutCompleted } =
    useTrackEvent()

  return useMutation({
    mutationKey: [...checkoutQueries.detail().queryKey, 'complete'] as const,
    mutationFn: () =>
      canaryClient.completeCheckout({
        successUrl: `${window.location.origin}/account/orders/${qid}?checkout_status=success`,
        cancelUrl: `${window.location.origin}/account/orders/${qid}?checkout_status=cancel`,
        declinedUrl: `${window.location.origin}/account/orders/${qid}?checkout_status=declined`,
      }),
    onSuccess: async (order) => {
      if (order.isFirstCheckout) {
        const user = queryClient.getQueryData(userQueries.detail().queryKey)
        trackFirstCheckoutCompleted({ order, email: user?.email })
      }
      trackCheckoutCompleted({ order })

      queryClient.setQueryData(orderQueries.detail(order.qid).queryKey, order)

      // If we have a redirectUrl, this is to an external payment provider, so we open it, replacing the current tab
      // We do this here in the useMutation onSuccess rather than .mutate() because
      // we _must_ navigate to the provider, even if the component calling the mutation has unmounted
      if (order.redirectUrl) {
        window.open(order.redirectUrl, '_self')
        return
      }

      // If we don't have a redirectUrl, we can navigate to the order confirmation page
      // We could do this in .mutate, but doing it here keeps everything together
      await router.replace({
        pathname: '/account/orders/[qid]',
        query: { qid: order.qid },
      })

      // We want to exclude the current checkout and current cart from the query invalidation
      // The process of completing the checkout will delete them on the backend, so if we try and refetch them
      // before we've navigated away from the checkout page, we'll get 404s
      const currentCheckout = queryClient.getQueryData(
        checkoutQueries.detail().queryKey,
      )
      const currentCart = currentCheckout
        ? queryClient.getQueryData(cartQueries.activeDetail().queryKey)
        : null

      await queryClient.invalidateQueries({
        predicate: (query) => {
          // We can use react query's fuzzy matching to not invalidate queries related to
          // the current checkout or the current cart
          // see examples: https://tkdodo.eu/blog/automatic-query-invalidation-after-mutations#use-the-meta-option
          const matchesCurrentCheckout = matchQuery(
            {
              queryKey: checkoutQueries.detail().queryKey,
            },
            query,
          )

          const matchesCurrentCart =
            currentCart &&
            matchQuery({ queryKey: cartQueries.activeDetail().queryKey }, query)

          if (matchesCurrentCheckout || matchesCurrentCart) {
            return false
          }

          return true
        },
      })
    },
  })
}

export function useUpdateCheckoutMutation(
  checkoutStep: 'address' | 'paymentMethod',
) {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const checkoutQueries = getCheckoutQueries(canaryClient)
  const { trackCheckoutStepCompleted } = useTrackEvent()

  return useMutation({
    mutationKey: [...checkoutQueries.detail().queryKey, 'update'] as const,
    mutationFn: (data: PatchedCheckoutRequest) =>
      canaryClient.updateCheckout(data),
    onSuccess: async (checkout) => {
      trackCheckoutStepCompleted({
        step: checkoutStep,
        checkout,
      })
      queryClient.setQueryData(checkoutQueries.detail().queryKey, checkout)
      await queryClient.invalidateQueries()
    },
  })
}

export function useIsCheckoutUpdating() {
  const canaryClient = useCanaryClient()
  const checkoutQueries = getCheckoutQueries(canaryClient)
  return (
    useIsMutating({
      mutationKey: [...checkoutQueries.detail().queryKey, 'update'],
    }) > 0
  )
}

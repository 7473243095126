import type { Country } from '@qogita/canary-types'

import COUNTRIES from './data/countries.json'

export const countries = COUNTRIES as {
  name: string
  countryCode: Country
  callingCode: string[]
}[]

export const ALL_COUNTRIES = countries

/**
 * @description Returns with the full name of the country with the provided country code
 * @param countryCode ISO 3166-1 alpha-2 code of the country
 * @returns Full name of the country
 */
export const getCountryName = (countryCode: Country): string => {
  const country = countries.find(
    (country) => country.countryCode === countryCode,
  )
  return country ? country.name : ''
}

export const ALL_COUNTRY_CODES = countries.map(
  (country) => country.countryCode,
) as [Country, ...Country[]]

/**
 * @description function to determine validity of country code string
 * @param countryCode
 * @returns boolean to determine valid country code type
 */
export const isValidCountryCode = (
  countryCode: string | undefined,
): countryCode is Country => {
  return ALL_COUNTRY_CODES.includes(countryCode as Country)
}

export type CountryDescriptor = {
  [key: string]: unknown
  name: string
  callingCode: string
}

import { cva, type VariantProps } from 'class-variance-authority'
import { HTMLAttributes } from 'react'

import { cn } from '../Utils/cn'

export const badgeVariants = cva(
  'inline-flex items-center border px-3 py-0.5',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-gray-200 text-gray-900',
        error: 'text-error-900 bg-error-50 border-transparent',
        caution: 'bg-caution-50 text-caution-900 border-transparent',
        success: 'bg-success-50 text-success-900 border-transparent',
        info: 'bg-info-100 text-info-900 border-transparent',
        primary: 'bg-primary-50 text-primary-900 border-transparent',
        reversed: 'border-transparent bg-gray-900 text-white',
        primaryReversed: 'bg-primary-700 border-transparent text-white',
        outlined: 'border-gray-300 text-gray-900',
      },
      size: {
        small: 'text-xs',
        medium: 'text-sm',
        large: 'text-base',
      },
      shape: {
        rounded: 'rounded-full',
        square: 'rounded',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'medium',
      shape: 'rounded',
    },
  },
)

type BadgeProps = HTMLAttributes<HTMLSpanElement> &
  VariantProps<typeof badgeVariants>
export function Badge({
  className,
  variant,
  size,
  shape,
  ...props
}: BadgeProps) {
  return (
    <span
      className={cn(
        'text-center',
        badgeVariants({ variant, size, shape }),
        className,
      )}
      {...props}
    />
  )
}

// @ts-check
// This is a .mjs file, so that it can be imported into
// environment.mjs, which can be imported into next.config.mjs to get build
// time environment variable validation.
import { booleanEnvironmentSchema } from '@qogita/validation/environment'
import { trimmedString } from '@qogita/validation/string'
import { createEnv } from '@t3-oss/env-nextjs'
import { z } from 'zod'
/**
 * client-side variable object with zod schema
 */
const client = {
  NEXT_PUBLIC_ENABLE_BOT_DETECTION: booleanEnvironmentSchema(),
  NEXT_PUBLIC_VERCEL_URL: trimmedString(),
  NEXT_PUBLIC_BASE_URL: trimmedString().url(),
  NEXT_PUBLIC_API_BASE_URL: z.literal('/api/gateway'),
  NEXT_PUBLIC_IMAGE_BASE_URL: trimmedString().url(),
  NEXT_PUBLIC_SEGMENT_WRITE_KEY: trimmedString(),
  NEXT_PUBLIC_ANALYTICS_ENABLED: booleanEnvironmentSchema(),
  NEXT_PUBLIC_GA_MEASUREMENT_ID: trimmedString(),
  NEXT_PUBLIC_GOOGLE_ADS_TAG_ID: trimmedString(),
  NEXT_PUBLIC_META_PIXEL_ID: trimmedString(),
  NEXT_PUBLIC_BING_TAG_ID: trimmedString(),
  NEXT_PUBLIC_UPTIME_ROBOT_READ_ONLY_API_KEY: trimmedString(),
  NEXT_PUBLIC_LOQATE_API_KEY: trimmedString(),
  NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID: trimmedString(),
  NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN: trimmedString(),
  NEXT_PUBLIC_DATADOG_RUM_ENABLED: booleanEnvironmentSchema(),
  NEXT_PUBLIC_DATADOG_CSP_REPORT_TOKEN: trimmedString(),
  NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID: trimmedString(),
  NEXT_PUBLIC_CLAIM_BY_SALE_ENABLED: booleanEnvironmentSchema(),
  NEXT_PUBLIC_ZAPIER_CONTACT_URL: z.literal(
    'https://hooks.zapier.com/hooks/catch/13184436/b7zeja8',
  ),
  // Vercel variables with defaults to allow local development
  NEXT_PUBLIC_VERCEL_ENV: z.enum(['development', 'preview', 'production']),
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA: trimmedString(),
  NEXT_PUBLIC_VERCEL_BRANCH_URL: trimmedString(),
  NEXT_PUBLIC_ENABLE_SUPPORT_WIDGET: booleanEnvironmentSchema(),
  NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN: trimmedString(),
  NEXT_PUBLIC_META_CONVERSIONS_API_VERSION: trimmedString(),
}

/**
 * server-side variable object with zod schema
 */
const server = {
  API_BASE_DOMAIN: trimmedString().url(),
  SESSION_COOKIE_NAME: z.literal('qsession'),
  SESSION_PASSWORD: trimmedString().min(32),
  SITEMAP_BASE_URL: trimmedString().url(),
  IS_E2E: booleanEnvironmentSchema().default(false),
  CI: booleanEnvironmentSchema(),
  MODE: trimmedString(),
  LAUNCHDARKLY_NODE_SDK_KEY: trimmedString(),
  DATADOG_NODE_API_KEY: trimmedString(),
  NODE_ENV: z.enum(['development', 'production', 'test', 'preview']),
  AWS_S3_REGION: trimmedString(),
  AWS_ACCESS_KEY_ID: trimmedString(),
  AWS_SECRET_ACCESS_KEY: trimmedString(),
  AWS_BUCKET_NAME: trimmedString(),
  HUBSPOT_ACCESS_TOKEN: trimmedString(),
  ZAPIER_MARKETPALCE_INVOICE_WEBHOOK_URL: trimmedString(),
  // Vercel variables with defaults to allow local development
  VERCEL_ENV: z.enum(['development', 'preview', 'production']),
  VERCEL_GIT_COMMIT_SHA: trimmedString(),
  TAPFILIATE_API_KEY: trimmedString(),
  META_CONVERSIONS_API_ACCESS_TOKEN: trimmedString(),
}

/**
 * Environment object with manual declaration of runtime variables
 * (can't be destructured on the fly because of the way Next.js works)
 */
// Ignoring Prettier to not wrap the code to multiple lines so it's easier to read
// prettier-ignore
export const environment = createEnv({
  client,
  server,
  runtimeEnv: {
    DATADOG_NODE_API_KEY: process.env.DATADOG_NODE_API_KEY,
    API_BASE_DOMAIN: process.env.API_BASE_DOMAIN,
    SESSION_COOKIE_NAME: process.env.SESSION_COOKIE_NAME,
    SESSION_PASSWORD: process.env.SESSION_PASSWORD,
    SITEMAP_BASE_URL: process.env.SITEMAP_BASE_URL,
    CI: process.env.CI,
    MODE: process.env.MODE,
    IS_E2E: process.env.IS_E2E,
    LAUNCHDARKLY_NODE_SDK_KEY: process.env.LAUNCHDARKLY_NODE_SDK_KEY,
    NODE_ENV: process.env.NODE_ENV,
    VERCEL_ENV: process.env.VERCEL_ENV,
    AWS_S3_REGION: process.env.AWS_S3_REGION,
    AWS_ACCESS_KEY_ID: process.env.AWS_ACCESS_KEY_ID,
    AWS_SECRET_ACCESS_KEY: process.env.AWS_SECRET_ACCESS_KEY,
    AWS_BUCKET_NAME: process.env.AWS_BUCKET_NAME,
    HUBSPOT_ACCESS_TOKEN: process.env.HUBSPOT_ACCESS_TOKEN,
    ZAPIER_MARKETPALCE_INVOICE_WEBHOOK_URL: process.env.ZAPIER_MARKETPALCE_INVOICE_WEBHOOK_URL,
    VERCEL_GIT_COMMIT_SHA: process.env.VERCEL_GIT_COMMIT_SHA,
    TAPFILIATE_API_KEY: process.env.TAPFILIATE_API_KEY,
    META_CONVERSIONS_API_ACCESS_TOKEN: process.env.META_CONVERSIONS_API_ACCESS_TOKEN,
    // CLIENT SIDE ENVIRONMENT VARIABLES
    NEXT_PUBLIC_ENABLE_BOT_DETECTION: process.env.NEXT_PUBLIC_ENABLE_BOT_DETECTION,
    NEXT_PUBLIC_VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,
    NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
    NEXT_PUBLIC_VERCEL_BRANCH_URL: process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL,
    NEXT_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
    NEXT_PUBLIC_API_BASE_URL: process.env.NEXT_PUBLIC_API_BASE_URL,
    NEXT_PUBLIC_IMAGE_BASE_URL: process.env.NEXT_PUBLIC_IMAGE_BASE_URL,
    NEXT_PUBLIC_SEGMENT_WRITE_KEY: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    NEXT_PUBLIC_ANALYTICS_ENABLED: process.env.NEXT_PUBLIC_ANALYTICS_ENABLED,
    NEXT_PUBLIC_GA_MEASUREMENT_ID: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
    NEXT_PUBLIC_GOOGLE_ADS_TAG_ID: process.env.NEXT_PUBLIC_GOOGLE_ADS_TAG_ID,
    NEXT_PUBLIC_META_PIXEL_ID: process.env.NEXT_PUBLIC_META_PIXEL_ID,
    NEXT_PUBLIC_BING_TAG_ID: process.env.NEXT_PUBLIC_BING_TAG_ID,
    NEXT_PUBLIC_UPTIME_ROBOT_READ_ONLY_API_KEY: process.env.NEXT_PUBLIC_UPTIME_ROBOT_READ_ONLY_API_KEY,
    NEXT_PUBLIC_LOQATE_API_KEY: process.env.NEXT_PUBLIC_LOQATE_API_KEY,
    NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID: process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID,
    NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
    NEXT_PUBLIC_DATADOG_RUM_ENABLED: process.env.NEXT_PUBLIC_DATADOG_RUM_ENABLED,
    NEXT_PUBLIC_DATADOG_CSP_REPORT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_CSP_REPORT_TOKEN,
    NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID,
    NEXT_PUBLIC_CLAIM_BY_SALE_ENABLED: process.env.NEXT_PUBLIC_CLAIM_BY_SALE_ENABLED,
    NEXT_PUBLIC_ZAPIER_CONTACT_URL: process.env.NEXT_PUBLIC_ZAPIER_CONTACT_URL,
    NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    NEXT_PUBLIC_ENABLE_SUPPORT_WIDGET: process.env.NEXT_PUBLIC_ENABLE_SUPPORT_WIDGET,
    NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN: process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN,
    NEXT_PUBLIC_META_CONVERSIONS_API_VERSION: process.env.NEXT_PUBLIC_META_CONVERSIONS_API_VERSION,
  },
});

import { cn } from '@qogita/ui/utils/cn'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'

import { CenteredContent } from '#components/Marketing/CenteredContent'
import { TwoColumnPanelNewStoryblok } from '#types/storyblok-component-types'

export function TwoColumnPanelNew({
  blok,
}: {
  blok: TwoColumnPanelNewStoryblok
}) {
  const { layout } = blok
  return (
    <div {...storyblokEditable(blok)}>
      <CenteredContent>
        <div
          className={cn(
            "grid grid-cols-1 gap-8 py-12 [grid-template-areas:'header'_'col-1'_'col-2'] md:grid-cols-3 md:grid-rows-[auto_1fr] md:gap-x-24",
            {
              // Layout for wide-left
              // || header | header | col-2 ||
              // || col-1  | col-1  | col-2 ||
              'md:[grid-template-areas:"header_header_col-2"_"col-1_col-1_col-2"]':
                layout === 'wide-column-one',
              // Layout for wide-right
              // || col-1 | header | header ||
              // || col-1 | col-2  | col-2  ||
              'md:[grid-template-areas:"col-1_header_header"_"col-1_col-2_col-2"]':
                layout === 'wide-column-two',
            },
          )}
        >
          <div className="[grid-area:header]">
            {blok.header.map((headerBlok) => (
              <StoryblokComponent key={headerBlok._uid} blok={headerBlok} />
            ))}
          </div>
          {blok.columns.map((column, index) => (
            <div
              key={column._uid}
              className={cn({
                '[grid-area:col-1]': index === 0,
                '[grid-area:col-2]': index === 1,
              })}
            >
              <StoryblokComponent className="gap-6 md:gap-8" blok={column} />
            </div>
          ))}
        </div>
      </CenteredContent>
    </div>
  )
}

import { cn } from '../Utils/cn'

export const LoadingIcon = ({ className }: { className?: string }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    className={cn('size-4.5 animate-spin fill-current', className)}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.232 16.778c.097.614-.322 1.197-.943 1.217a8.999 8.999 0 1 1 7.158-14.049c.349.514.123 1.197-.43 1.479-.554.282-1.224.054-1.594-.445a6.75 6.75 0 1 0-5.488 10.77c.622.006 1.2.414 1.297 1.028Z" />
  </svg>
)

import { OrderRetrieve } from '@qogita/canary-types'
import { cn } from '@qogita/ui/utils/cn'

import { getCurrencyFormatter } from '#lib/currency'

/**
 * Format a currency amount in accounting format with currency symbol
 */
export function CurrencyAmount({
  amount,
  currency,
  className,
}: {
  amount: number | string
  currency: OrderRetrieve['currency']
  className?: string
}) {
  const currencyFormatter = getCurrencyFormatter(currency)
  return (
    <span className={cn('whitespace-nowrap', className)}>
      {currencyFormatter(amount)}
    </span>
  )
}

import { Anchor } from '@qogita/ui/anchor'
import { Button } from '@qogita/ui/button'
import { Modal } from '@qogita/ui/modal'
import { useEffect, useState } from 'react'
import { useUser } from 'src/api/user-queries'
import { z } from 'zod'

import { getCookie, removeBrowserCookie, setBrowserCookie } from '#lib/cookie'

const cookieKey = 'q_buyer_cannot_checkout_alert_state'

const cookieSchema = z.literal('dismissed')

export function BuyerCannotCheckoutAlert() {
  const { data: user } = useUser()
  const [alertState, setAlertState] = useState<
    'unknown' | 'dismissed' | 'not-dismissed'
  >('unknown')

  useEffect(() => {
    // Load initial state from cookie
    const cookie = getCookie(document.cookie, cookieKey)
    const parsedCookie = cookieSchema.safeParse(cookie)
    if (parsedCookie.success) {
      setAlertState(parsedCookie.data)
    } else {
      setAlertState('not-dismissed')
    }
  }, [])

  useEffect(() => {
    // If user is no longer blocked, remove the cookie so we can show alert again
    // if user is ever re-blocked
    if (user?.canBuyerCheckout) {
      removeBrowserCookie(cookieKey)
    }
  }, [user])

  const handleDismissAlert = () => {
    setAlertState('dismissed')
    setBrowserCookie(cookieKey, 'dismissed', { path: '/' })
  }

  if (!user) return null

  const isAlertVisible =
    alertState === 'not-dismissed' && !user.canBuyerCheckout
  return (
    <Modal
      open={isAlertVisible}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          handleDismissAlert()
        }
      }}
    >
      <Modal.Content>
        <Modal.Header>Unable to place orders</Modal.Header>
        <Modal.Body>
          <h3 className="mb-2 font-bold text-gray-900">
            Your VAT number is missing or invalid
          </h3>
          <p>
            If you wish to checkout, please provide a valid VAT number by
            contacting our support team at{' '}
            <Anchor
              color="primary"
              size="inline"
              href="mailto:support@qogita.com"
              translate="no"
            >
              support@qogita.com
            </Anchor>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Actions>
            <Modal.Close asChild>
              <Button color="primary" appearance="outlined" size="medium">
                Close
              </Button>
            </Modal.Close>
            <Anchor
              onClick={handleDismissAlert}
              href="mailto:support@qogita.com"
              color="primary"
              appearance="contained"
            >
              Contact support
            </Anchor>
          </Modal.Actions>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}

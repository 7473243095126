'use client'

import { createContext, ReactNode, useContext } from 'react'

import { CanaryClient } from './client'

const CanaryClientContext = createContext<CanaryClient | null>(null)
export function useCanaryClient() {
  const canaryClient = useContext(CanaryClientContext)
  if (!canaryClient) {
    throw new Error(
      'useCanaryClient must be used within a CanaryClientProvider',
    )
  }
  return canaryClient
}

export function CanaryClientProvider({
  children,
  client,
}: {
  children: ReactNode
  client: CanaryClient
}) {
  return (
    <CanaryClientContext.Provider value={client}>
      {children}
    </CanaryClientContext.Provider>
  )
}

import { useQuery } from '@tanstack/react-query'

import { environment } from '#lib/environment.mjs'
import { BingScript } from '#lib/report/Bing'
import { getIsBotUser } from '#lib/report/bots'
import { GTagScript } from '#lib/report/Gtag'
import { MetaScript } from '#lib/report/Meta'
import { TikTokScript } from '#lib/report/TikTok'

export function TrackingScripts() {
  const isBotQuery = useQuery({
    queryKey: ['isBot'],
    queryFn: () =>
      getIsBotUser({
        isBotDetectionEnabled: environment.NEXT_PUBLIC_ENABLE_BOT_DETECTION,
      }),
  })

  const isBot = isBotQuery.isSuccess && isBotQuery.data
  if (isBot) {
    // We don't load tracking scripts for bots
    return null
  }

  const isAnalyticsEnabled = environment.NEXT_PUBLIC_ANALYTICS_ENABLED

  if (!isAnalyticsEnabled) {
    return null
  }

  return (
    <>
      <GTagScript />
      <MetaScript />
      <BingScript />
      <TikTokScript />
    </>
  )
}

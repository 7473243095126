import { useCanaryClient } from '@qogita/canary-client/provider'
import { Country } from '@qogita/canary-types'
import { ALL_COUNTRY_CODES } from '@qogita/countries'
import { getCurrencyFromCountryCode } from '@qogita/countries/currency'
import { logError } from '@qogita/logging/browser-logger'
import { useQuery } from '@tanstack/react-query'
import { getInfoQueries } from 'src/api/info-queries'
import { useUser } from 'src/api/user-queries'
import { z } from 'zod'

import { useAuthentication } from '#contexts/Authentication'
import { useConsent } from '#contexts/Consent'
import { includes } from '#lib/array'
import { getCookie, setBrowserCookie } from '#lib/cookie'

const preferencesSchema = (allowedCountries?: Country[]) =>
  z.object({
    currency: z.enum(['GBP', 'EUR', 'USD'], { required_error: 'Required' }),
    country: z
      .string()
      .min(1, 'Required')
      .refine(
        (value): value is Country =>
          includes(allowedCountries ?? ALL_COUNTRY_CODES, value),
        'Invalid country',
      ),
    showOnlyTraceableStock: z.boolean().default(false),
  })

const DEFAULT_LOCALE_KEY = 'localisation'
export const ANONYMOUS_PREFERENCES_KEY = 'q_anonymous_preferences'

const defaultPreferences = {
  country: 'NL',
  currency: 'EUR',
  showOnlyTraceableStock: false,
} as const

export const useLocalisationPreferences = () => {
  const { isAuthenticated } = useAuthentication()

  // Authed user preferences
  const userQuery = useUser()
  const userPreferences = userQuery.data
    ? {
        country: userQuery.data.country,
        currency: userQuery.data.currency,
        showOnlyTraceableStock: userQuery.data.showOnlyTraceableStock || false,
      }
    : null

  const canaryClient = useCanaryClient()
  const infoQueries = getInfoQueries(canaryClient)
  const { data: info } = useQuery(infoQueries.all())

  // anonymous user preferences
  const { hasConsent } = useConsent()
  const hasFunctionalConsent = hasConsent('functional')
  const anonymousPreferencesQuery = useQuery({
    queryKey: [
      'anonymousUserPreferences',
      { hasFunctionalConsent },
      info?.allowedShippingCountries,
    ],
    queryFn: async () => {
      if (!info?.allowedShippingCountries) {
        // Don't try running this til we know which countries are alloed
        return
      }
      if (!hasFunctionalConsent) {
        // If user doesn't consent to cookies we'll try getting preferences from local storage
        if (!window.localStorage) {
          // If local storage isn't available we'll just return the defaults
          return defaultPreferences
        }
        // Attempt to read the user's localization preferences from local storage
        const anonymousPreferencesLocalStorage = window.localStorage.getItem(
          ANONYMOUS_PREFERENCES_KEY,
        )
        if (!anonymousPreferencesLocalStorage) {
          // If there's nothing in local storage we'll return the defaults
          return defaultPreferences
        }

        try {
          // We've got something in local storage, we'll try and parse it and return it
          return {
            ...preferencesSchema(info.allowedShippingCountries).parse(
              JSON.parse(anonymousPreferencesLocalStorage),
            ),
            showOnlyTraceableStock: false, // force anonymous users to see all stock regardless of the local storage value
          }
        } catch {
          // The local storage item wasn't valid, so we'll empty it and just return the defaults
          logError('Invalid anonymous preferences in local storage')
          window.localStorage.removeItem(ANONYMOUS_PREFERENCES_KEY)
          return defaultPreferences
        }
      }

      // User does have functional consent, so lets try and read from cookies

      // Attempt to read the user's saved preferences cookie
      const anonymousPreferencesCookie = getCookie(
        document.cookie,
        ANONYMOUS_PREFERENCES_KEY,
      )
      if (anonymousPreferencesCookie) {
        try {
          // We've got a cookie, we'll try and parse it and return it
          return preferencesSchema(info.allowedShippingCountries).parse(
            JSON.parse(anonymousPreferencesCookie),
          )
        } catch {
          // The cookie wasn't valid, so clear it out so we don't try and read it again
          logError('Invalid anonymous preferences in cookie')
          setBrowserCookie(ANONYMOUS_PREFERENCES_KEY, '', { maxAge: -1 })
          // Then we'll move on and try the next level of fallback
        }
      }
      // We couldn't use the user's saved preferences, so we'll try and get the user's automatically detected location
      const defaultLocaleCookie = getCookie(document.cookie, DEFAULT_LOCALE_KEY)
      try {
        // Lets try and parse that locale cookie into preferences
        return preferencesSchema(info.allowedShippingCountries).parse({
          country: defaultLocaleCookie,
          currency: getCurrencyFromCountryCode(defaultLocaleCookie as Country),
        })
      } catch {
        // That wasn't valid either, so we'll just return the defaults
        return defaultPreferences
      }
    },
    // There's no point running this if we're authenticated
    enabled: !isAuthenticated && Boolean(info?.allowedShippingCountries),
  })

  const isPending = isAuthenticated
    ? userQuery.isPending
    : anonymousPreferencesQuery.isPending

  // If we've got authed user preferences we'll return those, otherwise we'll return the anonymous user preferences
  // And to avoid having a loading state, we'll fallback to the defaults
  // But we will provide an `isPending` in case any components need to know
  const preferences =
    userPreferences ?? anonymousPreferencesQuery.data ?? defaultPreferences
  return {
    ...preferences,
    isPending,
  }
}

/**
 * Reads the various anonymous user localization related cookies to work out the user's preferences
 * Or returns the default preferences as a fallback
 */
export function getAnonymousUserPreferences(
  cookies: Partial<{
    [key: string]: string
  }>,
) {
  const anonymousPreferencesCookie = cookies[ANONYMOUS_PREFERENCES_KEY]
  if (anonymousPreferencesCookie) {
    const parsedPreferences = preferencesSchema().safeParse(
      JSON.parse(anonymousPreferencesCookie),
    )
    if (parsedPreferences.success) {
      return parsedPreferences.data
    }
  }
  // If we didn't successfully parse the preferences, we'll look at the locale cookie
  const localeCookie = cookies[DEFAULT_LOCALE_KEY]
  if (localeCookie) {
    const parsedLocale = preferencesSchema().safeParse({
      country: localeCookie,
      currency: getCurrencyFromCountryCode(localeCookie as Country),
    })
    if (parsedLocale.success) {
      return parsedLocale.data
    }
  }

  return defaultPreferences
}

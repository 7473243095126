import { Badge } from '@qogita/ui/badge'
import { QogitaLogoWordmark } from '@qogita/ui/qogita-logo'
import { cn } from '@qogita/ui/utils/cn'
import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { CenteredContent } from '#components/CenteredContent'
import { MastheadAccount } from '#components/Masthead/Account'
import { MastheadSellViaQogitaLink } from '#components/Masthead/MastheadSellViaQogitaLink'
import { MonduLogo } from '#components/MonduLogo'
import { NextAnchor } from '#components/NextAnchor'
import { useAuthentication } from '#contexts/Authentication'

import { MastheadLocalisation } from './Localisation'
import { MastheadCart } from './MastheadCart'
import { SearchBox } from './SearchBox'
import { DesktopNavigation, MobileNavigation } from './TopLevelNavigation'

const VerticalDivider = ({ className }: { className?: string }) => {
  return <span className={clsx('h-6 border-l border-gray-500', className)} />
}

const navPagesBlocklist = new Set([
  '/checkout/[qid]/address',
  '/checkout/[qid]/payment',
  '/checkout/[qid]/review',
])

const searchBoxBlocklist = new Set(['/cart/supplier-inventory/[allocationQid]'])

export const Masthead = () => {
  const { isAuthenticated } = useAuthentication()
  const { pathname } = useRouter()

  const isOnNavigationVisiblePage = !navPagesBlocklist.has(pathname)

  return (
    <>
      <MonduMarketingBanner />
      <header className="flex w-full justify-center border-b border-b-[#000]">
        <div className="max-w-site-content flex w-full flex-wrap items-stretch justify-between gap-3 px-4 py-4 sm:flex-nowrap md:px-6">
          {isOnNavigationVisiblePage ? (
            <div className="flex flex-1 lg:hidden">
              <MobileNavigation />
            </div>
          ) : null}
          <Link href="/" className="flex w-[9rem] justify-center sm:w-auto">
            <QogitaLogoWordmark theme="black" className="h-[2.5rem] w-[9rem]" />
          </Link>
          <div
            className={cn(
              'order-last mt-4 w-full flex-grow sm:order-none sm:mx-6 sm:mt-0',
              {
                // Hide the search box on certain pages but keep it in the flow on larger screens (row-orientation) to prevent layout shift
                'hidden sm:invisible sm:block':
                  searchBoxBlocklist.has(pathname),
              },
            )}
          >
            <SearchBox />
          </div>
          <div
            className={clsx(
              'flex flex-1 items-center justify-end gap-3 sm:w-auto',
            )}
          >
            {isAuthenticated ? null : (
              <>
                <MastheadSellViaQogitaLink className="max-lg:hidden" />
                <VerticalDivider className="max-lg:hidden" />
              </>
            )}
            <MastheadLocalisation />
            <MastheadAccount />
            <MastheadCart />
          </div>
        </div>
      </header>
      {isOnNavigationVisiblePage ? (
        <div className="hidden lg:block">
          <DesktopNavigation />
        </div>
      ) : null}
    </>
  )
}

/** Temporary marketing banner for mondu, remove after 2024 */
function MonduMarketingBanner() {
  const { isAuthenticated } = useAuthentication()

  if (!isAuthenticated) {
    // Users can't sign up for mondu if they don't have an account
    return null
  }

  return (
    <div className="bg-gray-900 py-2 text-xs text-white sm:text-sm">
      <CenteredContent className="flex items-center gap-4 sm:justify-center">
        <Badge variant="primaryReversed" size="small" shape="rounded">
          New
        </Badge>
        <p className="flex items-center gap-[0.75ch]">
          Buy Now, Pay Later with{' '}
          <MonduLogo className="h-2.5 w-auto text-white sm:h-3" />
        </p>
        <NextAnchor
          href="/account/buy-now-pay-later"
          color="custom"
          size="inline"
          className="max-sm:ml-auto"
        >
          Learn more
        </NextAnchor>
      </CenteredContent>
    </div>
  )
}

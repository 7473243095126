import dolceGabbanaLogo from './assets/brands/dolce-gabbana.webp'
import garnierLogo from './assets/brands/garnier.webp'
import lattafaLogo from './assets/brands/lattafa.webp'
import lorealLogo from './assets/brands/loreal.webp'
import maybellineLogo from './assets/brands/maybelline.webp'
import niveaLogo from './assets/brands/nivea.webp'
import oralBLogo from './assets/brands/oral-b.webp'
import schwarzkopfLogo from './assets/brands/schwarzkopf.webp'
import theBodyShopLogo from './assets/brands/the-body-shop.webp'
import versaceLogo from './assets/brands/versace.webp'

export type NavigationCategory = {
  name: string
  slug?: string
  subCategories: {
    name: string
    slug?: string
    subCategories: {
      name: string
      slug: string
    }[]
  }[]
}

export type NavigationSubCategory = {
  name: string
  slug?: string
  subCategories?: NavigationSubCategory[]
}

/*
  @Deprecated: will be replaced by navigationCategories
 */
export const deprecatedNavigationCategories: Record<
  | 'makeup'
  | 'hair'
  | 'skincare'
  | 'bathShower'
  | 'nailCare'
  | 'shavingGrooming'
  | 'fragrance',
  NavigationCategory
> = {
  fragrance: {
    name: 'Fragrance',
    subCategories: [
      {
        name: 'Perfume & Cologne',
        slug: 'personal-care/cosmetics/perfume-cologne',
        subCategories: [],
      },
      {
        name: 'Aftershave',
        slug: 'personal-care/shaving-grooming/aftershave',
        subCategories: [],
      },
      {
        name: 'Deodorant & Anti-perspirant',
        slug: 'personal-care/deodorants-anti-perspirants',
        subCategories: [],
      },
    ],
  },
  makeup: {
    name: 'Makeup',
    slug: 'personal-care/cosmetics/make-up',
    subCategories: [
      {
        name: 'Eye Makeup',
        slug: 'personal-care/cosmetics/make-up/eye-make-up',
        subCategories: [
          {
            name: 'Eye Shadow',
            slug: 'personal-care/cosmetics/make-up/eye-make-up/eye-shadow',
          },
          {
            name: 'Eye Liner',
            slug: 'personal-care/cosmetics/make-up/eye-make-up/eyeliner',
          },
          {
            name: 'Mascara',
            slug: 'personal-care/cosmetics/make-up/eye-make-up/mascara',
          },
          {
            name: 'Eyebrow Enhancers',
            slug: 'personal-care/cosmetics/make-up/eye-make-up/eyebrow-enhancers',
          },
        ],
      },
      {
        name: 'Face Makeup',
        slug: 'personal-care/cosmetics/make-up/face-makeup',
        subCategories: [
          {
            name: 'Concealers',
            slug: 'personal-care/cosmetics/make-up/face-makeup/concealers',
          },
          {
            name: 'Contour',
            slug: 'personal-care/cosmetics/make-up/face-makeup/contouring',
          },
          {
            name: 'Face Powders',
            slug: 'personal-care/cosmetics/make-up/face-makeup/face-powders',
          },
          {
            name: 'Face Primer',
            slug: 'personal-care/cosmetics/make-up/face-makeup/face-primer',
          },
          {
            name: 'Foundations & Primers',
            slug: 'personal-care/cosmetics/make-up/face-makeup/foundations-powders',
          },
          {
            name: 'Highlighters & Luminisers',
            slug: 'personal-care/cosmetics/make-up/face-makeup/highlighters-luminisers',
          },
        ],
      },
      {
        name: 'Lip Makeup',
        slug: 'personal-care/cosmetics/make-up/lip-makeup',
        subCategories: [
          {
            name: 'Lip Gloss',
            slug: 'personal-care/cosmetics/make-up/lip-makeup/lip-gloss',
          },
          {
            name: 'Lip Liner',
            slug: 'personal-care/cosmetics/make-up/lip-makeup/lip-liner',
          },
          {
            name: 'Lip Primer',
            slug: 'personal-care/cosmetics/make-up/lip-makeup/lip-primer',
          },
          {
            name: 'Lipstick',
            slug: 'personal-care/cosmetics/make-up/lip-makeup/lipstick',
          },
          {
            name: 'Lip & Cheek Stain',
            slug: 'personal-care/cosmetics/make-up/lip-makeup/lip-cheek-stains',
          },
        ],
      },
    ],
  },
  hair: {
    name: 'Hair',
    slug: 'personal-care/haircare',
    subCategories: [
      {
        name: 'Hair Care',
        slug: 'personal-care/haircare',
        subCategories: [
          {
            name: 'Shampoo & Conditioner',
            slug: 'personal-care/haircare/shampoo-conditioner',
          },
          {
            name: 'Hair Masks',
            slug: 'personal-care/haircare/hair-masks',
          },
          {
            name: 'Hair Colouring',
            slug: 'personal-care/haircare/hair-colouring',
          },
          {
            name: 'Hair Loss Treatments',
            slug: 'personal-care/haircare/hair-loss-treatments',
          },
        ],
      },
      {
        name: 'Hair Styling',
        subCategories: [
          {
            name: 'Hair Combs & Brushes',
            slug: 'personal-care/haircare/hair-styling-tools/combs-brushes/',
          },
          {
            name: 'Hair Curlers',
            slug: 'personal-care/haircare/hair-styling-tools/hair-curlers/',
          },
          {
            name: 'Hair Dryers',
            slug: 'personal-care/haircare/hair-styling-tools/hair-dryers',
          },
          {
            name: 'Hair Straighteners',
            slug: 'personal-care/haircare/hair-styling-tools/hair-straighteners',
          },
          {
            name: 'Hair Styling Accessories',
            slug: 'personal-care/haircare/hair-styling-tool-accessories',
          },
        ],
      },
    ],
  },
  skincare: {
    name: 'Skincare',
    slug: 'personal-care/cosmetics/skincare',
    subCategories: [
      {
        name: 'Face',
        subCategories: [
          {
            name: 'Facial Cleansers',
            slug: 'personal-care/cosmetics/skincare/facial-cleansers',
          },
          {
            name: 'Toners',
            slug: 'personal-care/cosmetics/skincare/toners-astringents/toners',
          },
          {
            name: 'Lip Balms',
            slug: 'personal-care/cosmetics/skincare/lip-balms-treatments',
          },
          {
            name: 'Face Masks',
            slug: 'personal-care/cosmetics/skincare/skin-care-masks-peels',
          },
          {
            name: 'Makeup Removers',
            slug: 'personal-care/cosmetics/skincare/make-up-removers',
          },
          {
            name: 'Astringents',
            slug: 'personal-care/cosmetics/skincare/toners-astringents/astringents',
          },
        ],
      },
      {
        name: 'Body',
        subCategories: [
          {
            name: 'Body Oil',
            slug: 'personal-care/cosmetics/skincare/body-oil',
          },
          {
            name: 'Body Powder',
            slug: 'personal-care/cosmetics/skincare/body-powder',
          },
          {
            name: 'Lotions & Moisturisers',
            slug: 'personal-care/cosmetics/skincare/lotions-moisturisers',
          },
          {
            name: 'Petroleum Jelly',
            slug: 'personal-care/cosmetics/skincare/petroleum-jelly',
          },
        ],
      },
      {
        name: 'Sun Care & Tanning',
        subCategories: [
          {
            name: 'Sunscreen',
            slug: 'personal-care/cosmetics/skincare/sunscreen',
          },
          {
            name: 'Self Tanning',
            slug: 'personal-care/cosmetics/skincare/tanning-products',
          },
        ],
      },
    ],
  },
  bathShower: {
    name: 'Bath & Shower',
    slug: 'personal-care/cosmetics/bath-body/',
    subCategories: [
      {
        name: 'Cleansers',
        subCategories: [
          {
            name: 'Hand Sanitiser',
            slug: 'personal-care/cosmetics/bath-body/hand-sanitisers',
          },
          {
            name: 'Liquid Hand Soap',
            slug: 'personal-care/cosmetics/bath-body/liquid-hand-soap',
          },
          {
            name: 'Body Wash',
            slug: 'personal-care/cosmetics/bath-body/body-wash',
          },
          {
            name: 'Bar Soap',
            slug: 'personal-care/cosmetics/bath-body/bar-soap',
          },
          {
            name: 'Bath Additives',
            slug: 'personal-care/cosmetics/bath-body/bath-additives',
          },
          {
            name: 'Hygienic Wipes',
            slug: 'personal-care/cosmetics/bath-body/adult-hygienic-wipes',
          },
        ],
      },
      {
        name: 'Accessories',
        subCategories: [
          {
            name: 'Bath Sponges & Loofahs',
            slug: 'personal-care/cosmetics/bath-body/bath-sponges-loofahs',
          },
          {
            name: 'Bath Brushes',
            slug: 'personal-care/cosmetics/bath-body/bath-brushes',
          },
        ],
      },
    ],
  },
  nailCare: {
    name: 'Nail Care',
    slug: 'personal-care/cosmetics/nail-care',
    subCategories: [
      {
        name: 'Nail Design',
        subCategories: [
          {
            name: 'Nail Polish',
            slug: 'personal-care/cosmetics/nail-care/nail-polishes',
          },
          {
            name: 'Nail Polish Remover',
            slug: 'personal-care/cosmetics/nail-care/nail-polish-removers',
          },
          {
            name: 'False Nails',
            slug: 'personal-care/cosmetics/nail-care/false-nails',
          },
          {
            name: 'Nail Kits',
            slug: 'personal-care/cosmetics/nail-care/nail-art-kits-accessories',
          },
          {
            name: 'Nail Creams & Oils',
            slug: 'personal-care/cosmetics/nail-care/cuticle-creams-oils',
          },
        ],
      },
      {
        name: 'Nail Tools',
        slug: 'personal-care/cosmetics/cosmetic-tools/nail-tools',
        subCategories: [
          {
            name: 'Nail Clippers',
            slug: 'personal-care/cosmetics/cosmetic-tools/nail-tools/nail-clippers',
          },
          {
            name: 'Nail Buffers',
            slug: 'personal-care/cosmetics/cosmetic-tools/nail-tools/nail-buffers',
          },
          {
            name: 'Manicure Tool Sets',
            slug: 'personal-care/cosmetics/cosmetic-tools/nail-tools/manicure-tool-sets',
          },
        ],
      },
    ],
  },
  shavingGrooming: {
    name: 'Shaving & Grooming',
    slug: 'personal-care/shaving-grooming',
    subCategories: [
      {
        name: 'Shaving',
        subCategories: [
          {
            name: 'Electric Razors',
            slug: 'personal-care/shaving-grooming/electric-razors',
          },
          {
            name: 'Hair Clippers & Trimmers',
            slug: 'personal-care/shaving-grooming/hair-clippers-trimmers',
          },
          {
            name: 'Razors',
            slug: 'personal-care/shaving-grooming/razors-razor-blades',
          },
          {
            name: 'Shaving Kits',
            slug: 'personal-care/shaving-grooming/shaving-kits',
          },
        ],
      },
      {
        name: 'Hair Removal',
        slug: 'personal-care/shaving-grooming/hair-removal',
        subCategories: [
          {
            name: 'Waxing Kits',
            slug: 'personal-care/shaving-grooming/hair-removal/waxing-kits-supplies',
          },
          {
            name: 'Laser Hair Removal',
            slug: 'personal-care/shaving-grooming/hair-removal/laser-ipl-hair-removal-devices/',
          },
          {
            name: 'Hair Removal Cream',
            slug: 'personal-care/shaving-grooming/hair-removal/depilatories',
          },
        ],
      },
      {
        name: 'Accessories',
        subCategories: [
          {
            name: 'Shaving Cream',
            slug: 'personal-care/shaving-grooming/shaving-cream',
          },
          {
            name: 'Shaving Brushes',
            slug: 'personal-care/shaving-grooming/shaving-brushes',
          },
          {
            name: 'Shaving Bowls',
            slug: 'personal-care/shaving-grooming/shaving-bowls-mugs',
          },
        ],
      },
    ],
  },
}

export const navigationCategories: Record<
  | 'health'
  | 'body'
  | 'face'
  | 'hair'
  | 'makeup'
  | 'fragrance'
  | 'homeLifestyle',
  NavigationCategory
> = {
  health: {
    name: 'Health',
    slug: 'health',
    subCategories: [
      {
        name: 'First aid',
        slug: 'health/first-aid',
        subCategories: [
          {
            name: 'Bandages',
            slug: 'health/first-aid/bandage-material',
          },
          {
            name: 'Plasters',
            slug: 'health/first-aid/plasters',
          },
          {
            name: 'Hand hygiene',
            slug: 'health/first-aid/hand-hygiene',
          },
          {
            name: 'Antiseptics & cleaning supplies',
            slug: 'health/first-aid/antiseptics-cleaning-supplies',
          },
          {
            name: 'Equipment',
            slug: 'health/first-aid/equipment',
          },
        ],
      },
      {
        name: 'Oral Care',
        slug: 'health/oral-care',
        subCategories: [
          {
            name: 'Toothpaste',
            slug: 'health/oral-care/toothpaste',
          },
          {
            name: 'Toothbrushes & Tongue cleaners',
            slug: 'health/oral-care/toothbrushes-tongue-cleaners',
          },
          {
            name: 'Electric toothbrushes',
            slug: 'health/oral-care/electric-toothbrushes',
          },
          {
            name: 'Mouthwash',
            slug: 'health/oral-care/mouthwash',
          },
          {
            name: 'Teeth whiteners',
            slug: 'health/oral-care/teeth-whiteners',
          },
        ],
      },
      {
        name: 'Diet, Lifestyle & Wellness',
        slug: 'health/diet-lifestyle-wellness',
        subCategories: [
          {
            name: 'Nutrition',
            slug: 'health/diet-lifestyle-wellness/nutrition',
          },
          {
            name: 'Fitness',
            slug: 'health/diet-lifestyle-wellness/fitness',
          },
          {
            name: 'Weight loss',
            slug: 'health/diet-lifestyle-wellness/weight-loss',
          },
          {
            name: 'Massage',
            slug: 'health/diet-lifestyle-wellness/massage',
          },
          {
            name: 'Aromatherapy & Essential oils',
            slug: 'health/diet-lifestyle-wellness/aromatherapy-essential-oils',
          },
        ],
      },
      {
        name: 'Supplements & Vitamins',
        slug: 'health/supplements-vitamins',
        subCategories: [
          {
            name: 'Vitamin',
            slug: 'health/supplements-vitamins/vitamin',
          },
          {
            name: 'Minerals',
            slug: 'health/supplements-vitamins/minerals',
          },
          {
            name: 'Anti-aging',
            slug: 'health/supplements-vitamins/anti-aging',
          },
        ],
      },
      {
        name: 'Skin, Hair & Nails',
        slug: 'health/skin-hair-nails',
        subCategories: [
          {
            name: 'Inflammations',
            slug: 'health/skin-hair-nails/inflammations',
          },
          {
            name: 'Hands & Feet',
            slug: 'health/skin-hair-nails/hands-feet',
          },
          {
            name: 'Calluses & Cracks',
            slug: 'health/skin-hair-nails/calluses-cracks',
          },
        ],
      },
      {
        name: 'Eyes Nose & Ears',
        slug: 'health/eyes-nose-ears',
        subCategories: [
          {
            name: 'Ear care',
            slug: 'health/eyes-nose-ears/ear-care',
          },
          {
            name: 'Eye drops',
            slug: 'health/eyes-nose-ears/eye-drops',
          },
        ],
      },
      {
        name: 'Dermatological Skin Care',
        slug: 'health/dermatological-skin-care',
        subCategories: [
          {
            name: 'Acne',
            slug: 'health/dermatological-skin-care/acne',
          },
          {
            name: 'Rosacea',
            slug: 'health/dermatological-skin-care/rosacea',
          },
          {
            name: 'Neurodermatitis',
            slug: 'health/dermatological-skin-care/neurodermatitis',
          },
        ],
      },
    ],
  },
  body: {
    name: 'Body',
    slug: 'body',
    subCategories: [
      {
        name: 'Body care',
        slug: 'body/body-care',
        subCategories: [
          {
            name: 'Body lotion',
            slug: 'body/body-care/body-lotion',
          },
          {
            name: 'Body butter',
            slug: 'body/body-care/body-mousse',
          },
          {
            name: 'Body oil',
            slug: 'body/body-care/body-oil',
          },
          {
            name: 'Anti-cellulite',
            slug: 'body/body-care/anti-cellulite',
          },
          {
            name: 'Body care sets',
            slug: 'body/body-care/body-care-sets',
          },
        ],
      },
      {
        name: 'Hand care',
        slug: 'body/hand-care',
        subCategories: [
          {
            name: 'Hand cleaning',
            slug: 'body/hand-care/hand-cleaning',
          },
          {
            name: 'Hand soap',
            slug: 'body/hand-care/hand-soap',
          },
          {
            name: 'Hand peeling',
            slug: 'body/hand-care/hand-peeling',
          },
          {
            name: 'Hand mask',
            slug: 'body/hand-care/hand-mask',
          },
          {
            name: 'Hand cream',
            slug: 'body/hand-care/hand-cream',
          },
        ],
      },
      {
        name: 'Sun care protection',
        slug: 'body/sun-care-protection',
        subCategories: [
          {
            name: 'Sun protection',
            slug: 'body/sun-care-protection/body-sun-protection',
          },
          {
            name: 'Aftersun',
            slug: 'body/sun-care-protection/aftersun',
          },
          {
            name: 'Self tanner',
            slug: 'body/sun-care-protection/body-self-tanner',
          },
          {
            name: 'Sun protection sets',
            slug: 'body/sun-care-protection/sun-protection-sets',
          },
        ],
      },
      {
        name: 'Nail care',
        slug: 'body/nail-care',
        subCategories: [
          {
            name: 'Nail clippers & Tools',
            slug: 'body/nail-care/nail-clippers-tools',
          },
          {
            name: 'Nail oil',
            slug: 'body/nail-care/nail-oil',
          },
          {
            name: 'Cuticle removers',
            slug: 'body/nail-care/cuticle-removers',
          },
          {
            name: 'Manicure sets',
            slug: 'body/nail-care/manicure-sets',
          },
          {
            name: 'Nail care sets',
            slug: 'body/nail-care/nail-care-sets',
          },
        ],
      },
      {
        name: 'Body Cleansing',
        slug: 'body/body-cleansing',
        subCategories: [
          {
            name: 'Shower gel',
            slug: 'body/body-cleansing/shower-gel',
          },
          {
            name: 'Shower foam',
            slug: 'body/body-cleansing/shower-foam',
          },
          {
            name: 'Shower oil',
            slug: 'body/body-cleansing/shower-oil',
          },
          {
            name: 'Soap',
            slug: 'body/body-cleansing/soap',
          },
          {
            name: 'Body scrub & peeling',
            slug: 'body/body-cleansing/body-scrub-peeling',
          },
          {
            name: 'Bath Salts & Bath Bombs',
            slug: 'body/body-cleansing/bath-salts-bath-bombs',
          },
        ],
      },
      {
        name: 'Foot Care',
        slug: 'body/foot-care',
        subCategories: [
          {
            name: 'Foot Bath',
            slug: 'body/foot-care/foot-bath',
          },
          {
            name: 'Foot Scrub',
            slug: 'body/foot-care/foot-scrub',
          },
          {
            name: 'Foot Mask',
            slug: 'body/foot-care/foot-mask',
          },
          {
            name: 'Foot Cream',
            slug: 'body/foot-care/foot-cream',
          },
          {
            name: 'Foot Spray',
            slug: 'body/foot-care/foot-spray',
          },
        ],
      },
      {
        name: 'Intimacy',
        slug: 'body/intimacy',
        subCategories: [
          {
            name: 'Lubricants',
            slug: 'body/intimacy/intimate-lubricants',
          },
          {
            name: 'Condoms',
            slug: 'body/intimacy/condoms',
          },

          {
            name: 'Sex Toys',
            slug: 'body/intimacy/sex-toys',
          },
        ],
      },
    ],
  },
  face: {
    name: 'Face',
    slug: 'face',
    subCategories: [
      {
        name: 'Facial Care',
        slug: 'face/facial-care',
        subCategories: [
          {
            name: 'Face cream',
            slug: 'face/facial-care/face-cream',
          },
          {
            name: 'Day cream',
            slug: 'face/facial-care/day-cream',
          },
          {
            name: 'Night cream',
            slug: 'face/facial-care/night-cream',
          },
          {
            name: 'Facial oil',
            slug: 'face/facial-care/facial-oil',
          },
        ],
      },
      {
        name: 'Face serum',
        slug: 'face/face-serum',
        subCategories: [
          {
            name: 'Hydrating serum',
            slug: 'face/face-serum/hydrating-serum',
          },
          {
            name: 'Vitamin serum',
            slug: 'face/face-serum/vitamin-serum',
          },
          {
            name: 'Anti aging serum',
            slug: 'face/face-serum/anti-aging-serum',
          },
          {
            name: 'Hyaluronic acid serum',
            slug: 'face/face-serum/hyaluronic-acid-serum',
          },
          {
            name: 'Collagen serum',
            slug: 'face/face-serum/collagen-serum',
          },
        ],
      },
      {
        name: 'Lip care',
        slug: 'face/lip-care',
        subCategories: [
          {
            name: 'Lip oil',
            slug: 'face/lip-care/lip-oil',
          },
          {
            name: 'Lip mask',
            slug: 'face/lip-care/lip-mask',
          },
          {
            name: 'Lip scrub',
            slug: 'face/lip-care/lip-scrub',
          },
          {
            name: 'Lip serum',
            slug: 'face/lip-care/lip-serum',
          },
        ],
      },
      {
        name: 'Beard care',
        slug: 'face/beard-care',
        subCategories: [
          {
            name: 'shaving',
            slug: 'face/beard-care/shaving',
          },
          {
            name: 'sets',
            slug: 'face/beard-care/beard-care-sets',
          },
          {
            name: 'accessories',
            slug: 'face/beard-care/beard-care-accessories',
          },
        ],
      },
      {
        name: 'Facial cleansing',
        slug: 'face/facial-cleansing',
        subCategories: [
          {
            name: 'Cleansing foam',
            slug: 'face/facial-cleansing/cleansing-foam',
          },
          {
            name: 'Cleansing gel',
            slug: 'face/facial-cleansing/cleansing-gel',
          },
          {
            name: 'Cleansing oil',
            slug: 'face/facial-cleansing/cleansing-oil',
          },
          {
            name: 'Cleansing milk',
            slug: 'face/facial-cleansing/cleansing-milk',
          },
          {
            name: 'Cleansing cream',
            slug: 'face/facial-cleansing/cleansing-cream',
          },
        ],
      },
      {
        name: 'Eye care',
        slug: 'face/eye-care',
        subCategories: [
          {
            name: 'Eye cream',
            slug: 'face/eye-care/eye-cream',
          },
          {
            name: 'Eye masks & eye pads',
            slug: 'face/eye-care/eye-masks-eye-pads',
          },
          {
            name: 'Eye serum',
            slug: 'face/eye-care/eye-serum',
          },
          {
            name: 'Eyelash serum & Eyebrow serum',
            slug: 'face/eye-care/eyelash-serum-eyebrow-serum',
          },
          {
            name: 'Eye gel',
            slug: 'face/eye-care/eye-gel',
          },
        ],
      },
      {
        name: 'Face mask',
        slug: 'face/face-mask',
        subCategories: [
          {
            name: 'Hydrating mask',
            slug: 'face/face-mask/hydrating-mask',
          },
          {
            name: 'Purifying mask',
            slug: 'face/face-mask/purifying-mask',
          },
          {
            name: 'Anti aging mask',
            slug: 'face/face-mask/anti-aging-mask',
          },
        ],
      },
    ],
  },
  hair: {
    name: 'Hair',
    slug: 'hair',
    subCategories: [
      {
        name: 'Hair Care',
        slug: 'hair/hair-care',
        subCategories: [
          {
            name: 'Shampoo',
            slug: 'hair/hair-care/shampoo',
          },
          {
            name: 'Conditioner',
            slug: 'hair/hair-care/conditioner',
          },
          {
            name: 'Hair oil & Hair serum',
            slug: 'hair/hair-care/hair-oil-hair-serum',
          },
          {
            name: 'Hair masks',
            slug: 'hair/hair-care/hair-masks',
          },
          {
            name: 'Dry shampoo',
            slug: 'hair/hair-care/dry-shampoo',
          },
        ],
      },
      {
        name: 'Hair styling',
        slug: 'hair/hair-styling',
        subCategories: [
          {
            name: 'Heat protection',
            slug: 'hair/hair-styling/heat-protection',
          },
          {
            name: 'Mousse',
            slug: 'hair/hair-styling/mousse',
          },
          {
            name: 'Gel',
            slug: 'hair/hair-styling/gel',
          },
          {
            name: 'Hairspray',
            slug: 'hair/hair-styling/hairspray',
          },
          {
            name: 'Wax',
            slug: 'hair/hair-styling/wax',
          },
        ],
      },
      {
        name: 'Styling tools',
        slug: 'hair/styling-tools',
        subCategories: [
          {
            name: 'Hair straighteners',
            slug: 'hair/styling-tools/hair-straighteners',
          },
          {
            name: 'Curling irons',
            slug: 'hair/styling-tools/curling-irons',
          },
          {
            name: 'Hot air brushes',
            slug: 'hair/styling-tools/hot-air-brushes',
          },
          {
            name: 'Hair dryers',
            slug: 'hair/styling-tools/hair-dryers',
          },
          {
            name: 'Hair clippers',
            slug: 'hair/styling-tools/hair-clippers',
          },
        ],
      },
      {
        name: 'Brushes & Combs',
        slug: 'hair/brushes-combs',
        subCategories: [
          {
            name: 'Round brushes',
            slug: 'hair/brushes-combs/round-brushes',
          },
          {
            name: 'Flat & Paddle brushes',
            slug: 'hair/brushes-combs/flat-paddle-brushes',
          },
          {
            name: 'Wooden Brushes',
            slug: 'hair/brushes-combs/wooden-brushes',
          },
          {
            name: 'Combs',
            slug: 'hair/brushes-combs/combs',
          },
          {
            name: 'Detanglers',
            slug: 'hair/brushes-combs/detanglers',
          },
        ],
      },
      {
        name: 'Hair coloring',
        slug: 'hair/hair-coloring',
        subCategories: [
          {
            name: 'Bleaching',
            slug: 'hair/hair-coloring/bleaching',
          },
          {
            name: 'Color rinse',
            slug: 'hair/hair-coloring/color-rinse',
          },
          {
            name: 'Hair dye',
            slug: 'hair/hair-coloring/hair-dye',
          },
          {
            name: 'Herbal hair dye',
            slug: 'hair/hair-coloring/herbal-hair-dye',
          },
          {
            name: 'Hairline paint',
            slug: 'hair/hair-coloring/hairline-paint',
          },
        ],
      },
      {
        name: 'Hair accessories',
        slug: 'hair/hair-accessories',
        subCategories: [
          {
            name: 'Hair clips & Hair clamps',
            slug: 'hair/hair-accessories/hair-clips-hair-clamps',
          },
          {
            name: 'Headbands',
            slug: 'hair/hair-accessories/headbands',
          },
          {
            name: 'Hair jewellery',
            slug: 'hair/hair-accessories/hair-jewellery',
          },
          {
            name: 'Hair elastics',
            slug: 'hair/hair-accessories/hair-elastics',
          },
          {
            name: 'Hair extensions',
            slug: 'hair/hair-accessories/hair-extensions',
          },
        ],
      },
    ],
  },
  makeup: {
    name: 'Makeup',
    slug: 'makeup',
    subCategories: [
      {
        name: 'Eyes',
        slug: 'makeup/eyes',
        subCategories: [
          {
            name: 'Eyeshadow',
            slug: 'makeup/eyes/eyeshadow',
          },
          {
            name: 'Mascara',
            slug: 'makeup/eyes/mascara',
          },
          {
            name: 'Eyeliner',
            slug: 'makeup/eyes/eyeliner',
          },
          {
            name: 'Sets & Pallets',
            slug: 'makeup/eyes/eye-sets-pallets',
          },
          {
            name: 'Eye pencil',
            slug: 'makeup/eyes/eye-pencil',
          },
        ],
      },
      {
        name: 'Complexion',
        slug: 'makeup/complexion',
        subCategories: [
          {
            name: 'Foundation',
            slug: 'makeup/complexion/foundation',
          },
          {
            name: 'Blush',
            slug: 'makeup/complexion/blush',
          },
          {
            name: 'Concealer',
            slug: 'makeup/complexion/concealer',
          },
          {
            name: 'Powder',
            slug: 'makeup/complexion/powder',
          },
          {
            name: 'Highlighter',
            slug: 'makeup/complexion/highlighter',
          },
        ],
      },
      {
        name: 'Lips',
        slug: 'makeup/lips',
        subCategories: [
          {
            name: 'Lipstick',
            slug: 'makeup/lips/lipstick',
          },
          {
            name: 'Lip gloss',
            slug: 'makeup/lips/lip-gloss',
          },
          {
            name: 'Lip liner',
            slug: 'makeup/lips/lip-liner',
          },
          {
            name: 'Lip balm',
            slug: 'makeup/lips/lip-balm',
          },
          {
            name: 'Lip plumper',
            slug: 'makeup/lips/lip-plumper',
          },
        ],
      },
      {
        name: 'Nails',
        slug: 'makeup/nails',
        subCategories: [
          {
            name: 'Nail polish',
            slug: 'makeup/nails/nail-polish',
          },
          {
            name: 'Gel polish',
            slug: 'makeup/nails/gel-polish',
          },
          {
            name: 'Nail care',
            slug: 'makeup/nails/nail-care',
          },
          {
            name: 'Nail Polish Remover',
            slug: 'makeup/nails/nail-polish-remover',
          },
          {
            name: 'Sets',
            slug: 'makeup/nails/nail-sets',
          },
        ],
      },
      {
        name: 'Eyebrows',
        slug: 'makeup/eyebrows',
        subCategories: [
          {
            name: 'Eyebrow Gel',
            slug: 'makeup/eyebrows/eyebrow-gel',
          },
          {
            name: 'Eyebrow Pencil',
            slug: 'makeup/eyebrows/eyebrow-pencil',
          },
          {
            name: 'Eyebrow Powder',
            slug: 'makeup/eyebrows/eyebrow-powder',
          },
          {
            name: 'Eyebrow Dye',
            slug: 'makeup/eyebrows/eyebrow-dye',
          },
          {
            name: 'Sets & Pallets',
            slug: 'makeup/eyebrows/eyebrow-sets-pallets',
          },
        ],
      },
      {
        name: 'Accessories',
        slug: 'makeup/accessories',
        subCategories: [
          {
            name: 'Eyelash combs',
            slug: 'makeup/accessories/eyelash-combs',
          },
          {
            name: 'Eyelash curlers',
            slug: 'makeup/accessories/eyelash-curlers',
          },
          {
            name: 'Makeup mirrors',
            slug: 'makeup/accessories/makeup-mirrors',
          },
          {
            name: 'Body makeup',
            slug: 'makeup/accessories/body-makeup',
          },
          {
            name: 'Makeup sponges',
            slug: 'makeup/accessories/makeup-sponges',
          },
        ],
      },
      {
        name: 'Makeup Brushes',
        slug: 'makeup/makeup-brushes',
        subCategories: [
          {
            name: 'Lip brushes',
            slug: 'makeup/makeup-brushes/lip-brushes',
          },
          {
            name: 'Foundation brushes',
            slug: 'makeup/makeup-brushes/foundation-brushes',
          },
          {
            name: 'Powder brushes',
            slug: 'makeup/makeup-brushes/powder-brushes',
          },
          {
            name: 'Eyeshadow brushes',
            slug: 'makeup/makeup-brushes/eyeshadow-brushes',
          },
          {
            name: 'Brush sets',
            slug: 'makeup/makeup-brushes/brush-sets',
          },
        ],
      },
    ],
  },
  fragrance: {
    name: 'Fragrance',
    slug: 'fragrance',
    subCategories: [
      {
        name: 'Perfume cologne',
        slug: 'fragrance/perfume-cologne',
        subCategories: [
          {
            name: 'Eau de toilette',
            slug: 'fragrance/perfume-cologne/eau-de-toilette',
          },
          {
            name: 'Eau de parfum',
            slug: 'fragrance/perfume-cologne/eau-de-parfum',
          },
          {
            name: 'Eau de cologne',
            slug: 'fragrance/perfume-cologne/eau-de-cologne',
          },
          {
            name: 'Extrait de parfum',
            slug: 'fragrance/perfume-cologne/extrait-de-parfum',
          },
          {
            name: 'Fragrance sets',
            slug: 'fragrance/perfume-cologne/fragrance-sets',
          },
        ],
      },
      {
        name: 'Deodorant & Anti Perspirant',
        slug: 'fragrance/deodorant-anti-perspirant',
        subCategories: [],
      },
      {
        name: 'Aftershave',
        slug: 'fragrance/aftershave',
        subCategories: [],
      },
    ],
  },
  homeLifestyle: {
    name: 'Home & Lifestyle',
    slug: 'home-lifestyle',
    subCategories: [
      {
        name: 'Jewellery',
        slug: 'home-lifestyle/jewellery',
        subCategories: [
          {
            name: 'Earrings',
            slug: 'home-lifestyle/jewellery/earrings',
          },
          {
            name: 'Bracelets',
            slug: 'home-lifestyle/jewellery/bracelets',
          },
          {
            name: 'Watches & wristbands',
            slug: 'home-lifestyle/jewellery/watches-wristbands',
          },
        ],
      },
      {
        name: 'Candles & holders',
        slug: 'home-lifestyle/candles-holders',
        subCategories: [
          {
            name: 'Candles',
            slug: 'home-lifestyle/candles-holders/candles',
          },
        ],
      },
      {
        name: 'Home fragrance',
        slug: 'home-lifestyle/home-fragrance',
        subCategories: [
          {
            name: 'Incense',
            slug: 'home-lifestyle/home-fragrance/incense',
          },
          {
            name: 'Diffusers',
            slug: 'home-lifestyle/home-fragrance/diffusers',
          },
        ],
      },
    ],
  },
}

export const navigationBrands = [
  {
    name: 'Maybelline New York',
    slug: 'maybelline-new-york',
    logo: maybellineLogo,
  },
  { name: 'Garnier', slug: 'garnier', logo: garnierLogo },
  { name: 'Dolce & Gabbana', slug: 'dolce-gabbana', logo: dolceGabbanaLogo },
  { name: 'Nivea', slug: 'nivea', logo: niveaLogo },
  { name: 'Versace', slug: 'versace', logo: versaceLogo },
  { name: 'Lattafa', slug: 'lattafa', logo: lattafaLogo },
  { name: "L'Oréal", slug: 'loreal', logo: lorealLogo },
  { name: 'Schwarzkopf', slug: 'schwarzkopf', logo: schwarzkopfLogo },
  { name: 'Oral-B', slug: 'oral-b', logo: oralBLogo },
  { name: 'The Body Shop', slug: 'the-body-shop', logo: theBodyShopLogo },
]

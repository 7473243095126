import { cn } from '@qogita/ui/utils/cn'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'

import { ColumnStoryblok } from '#types/storyblok-component-types'

export function Column({
  blok,
  className,
}: {
  blok: ColumnStoryblok
  /**
   * Additional classes to add to the root of the column element itself
   * useful for adjusting gap or padding
   * @default ''
   * @type string
   */
  className?: string
}) {
  const hasTopLevelHeading = Boolean(blok.heading)
  return (
    <div {...storyblokEditable(blok)}>
      {hasTopLevelHeading ? (
        <h3 className="mb-4 text-2xl font-bold">{blok.heading}</h3>
      ) : null}
      <div className={cn('flex flex-col gap-2', className)}>
        {blok.content.map((contentBlok) => {
          if (contentBlok.component === 'heading') {
            // If the column has a top level heading, the other headings should be h4
            // Otherwise they are h3
            const InnerHeadingComponent = hasTopLevelHeading ? 'h4' : 'h3'

            // And we want to add a little top margin to separate it from the previous content
            return (
              <InnerHeadingComponent
                key={contentBlok._uid}
                className="mt-4 font-bold"
              >
                {contentBlok.heading}
              </InnerHeadingComponent>
            )
          }
          if (
            contentBlok.component === 'cta' ||
            contentBlok.component === 'benefitsCard'
          ) {
            // We separate these 'section' type components out so we can add a little top margin
            return (
              <div className="mt-4" key={contentBlok._uid}>
                <StoryblokComponent blok={contentBlok} />
              </div>
            )
          }
          return (
            <StoryblokComponent key={contentBlok._uid} blok={contentBlok} />
          )
        })}
      </div>
    </div>
  )
}

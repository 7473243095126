import {
  CanaryClient,
  GetVariantByFidAndSlugSearchParams,
  GetVariantsSearchFacetsBrandNameSearchParams,
  GetVariantsSearchNewModelReturnType,
  GetVariantsSearchReturnType,
  GetVariantsSearchSearchParams,
  GetVariantsSearchSuggestionsSearchParams,
} from '@qogita/canary-client'
import { useCanaryClient } from '@qogita/canary-client/provider'
import {
  CategoryTreeFacetDistribution,
  VariantRequestRequest,
} from '@qogita/canary-types'
import { logError } from '@qogita/logging/browser-logger'
import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { replaceUndefinedValuesWithNull } from './utils'

export function getVariantQueries(canaryClient: CanaryClient) {
  const queries = {
    all: () => ['variants'] as const,
    allDetails: () => [...queries.all(), 'detail'] as const,
    detailByFidAndSlug: (
      {
        fid,
        slug,
        isAuthenticated,
      }: {
        fid: string
        slug: string
        /*
         * The response differs for authed vs unauthed users, some fields are null for unauthed
         * So we need to pass this flag to the queryKey to differentiate the cache
         */
        isAuthenticated: boolean
      },
      params: GetVariantByFidAndSlugSearchParams = {},
    ) =>
      queryOptions({
        queryKey: [
          ...queries.allDetails(),
          'byFidAndSlug',
          fid,
          slug,
          { isAuthenticated },
          replaceUndefinedValuesWithNull(params),
        ] as const,
        queryFn: () =>
          canaryClient.getVariantByFidAndSlug({ fid, slug }, params),
      }),
    allSearch: () => [...queries.all(), 'search'] as const,
    search: (
      params: GetVariantsSearchSearchParams = {},
      type: 'OPTIMIZER' | 'NEW_PRICING',
    ) =>
      queryOptions({
        queryKey: [
          ...queries.allSearch(),
          replaceUndefinedValuesWithNull(params),
          type,
        ] as const,
        queryFn: async (): Promise<VariantsSearchData> => {
          if (type === 'NEW_PRICING') {
            return canaryClient
              .getVariantsSearchNewModel(params)
              .then((data) => ({
                ...data,
                type,
              }))
          }
          return canaryClient.getVariantsSearch(params).then((data) => ({
            ...data,
            type,
          }))
        },
        select: (data) => {
          // Enrich the search results with the leaf category so we can more easily render its name
          const categorySlug = params.categories?.at(-1)
          if (!data.facets.category || !categorySlug) {
            // We specifically return undefined here to stop typescript from inferring this as a union of
            // PaginatedSearch | UseSearchData.
            // This ensures that the leafCategory key is always in the _type_ but it can be undefined as a value
            // Otherwise when we try and access `searchQuery.data.leafCategory` we get an error that leafCategory
            // doesn't exist on PaginatedSearch
            return { ...data, leafCategory: undefined }
          }

          const leafCategory = findSelectedCategory(
            [data.facets.category],
            categorySlug,
          )

          return { leafCategory, ...data }
        },
        staleTime: 1000 * 60 * 5, // 5 minutes
      }),
    allSearchSuggestions: () =>
      [...queries.allSearch(), 'suggestions'] as const,
    searchSuggestions: (params: GetVariantsSearchSuggestionsSearchParams) =>
      queryOptions({
        queryKey: [
          ...queries.allSearchSuggestions(),
          replaceUndefinedValuesWithNull(params),
        ] as const,
        queryFn: () => canaryClient.getVariantsSearchSuggestions(params),
      }),
    allSearchFacets: () => [...queries.allSearch(), 'facets'] as const,
    allSearchFacetsBrandName: () =>
      [...queries.allSearchFacets(), 'brandName'] as const,
    searchFacetsBrandName: (
      params: GetVariantsSearchFacetsBrandNameSearchParams = {},
    ) =>
      queryOptions({
        queryKey: [
          ...queries.allSearchFacetsBrandName(),
          replaceUndefinedValuesWithNull(params),
        ] as const,
        queryFn: () => canaryClient.getVariantsSearchFacetsBrandName(params),
      }),
    priceHistory: ({ fid, slug }: { fid: string; slug: string }) =>
      queryOptions({
        queryKey: [
          // We have to be authed to use this query
          ...queries.detailByFidAndSlug({ fid, slug, isAuthenticated: true })
            .queryKey,
          'priceHistory',
        ] as const,
        queryFn: () => canaryClient.getVariantPriceHistory({ fid, slug }),
      }),
  }

  return queries
}

/**
 * Depth first search of a category tree to find a specific category in the tree by its slug
 * Essentially, so we can get its name
 */
const findSelectedCategory = (
  categoryTree: CategoryTreeFacetDistribution[],
  categorySlug: string,
): Pick<CategoryTreeFacetDistribution, 'name' | 'slug'> => {
  for (const category of categoryTree) {
    if (category.slug === categorySlug) {
      return category
    }
    if (category.subCategories) {
      const matchingCategoryNode = findSelectedCategory(
        category.subCategories,
        categorySlug,
      )
      if (matchingCategoryNode) {
        return matchingCategoryNode
      }
    }
  }

  // We should always be able to select the appropriate leaf category from the category tree
  // If we can't, something has gone dreadfully wrong.
  // We shouldn't throw here because we should only use this leaf node for
  // display purposes. So we log an error and return empty data.
  logError('Could not find category in category tree', {
    categorySlug,
    categoryTree,
  })
  return {
    name: '',
    slug: '',
  }
}

export type VariantsSearchData = (
  | (GetVariantsSearchReturnType & { type: 'OPTIMIZER' })
  | (GetVariantsSearchNewModelReturnType & { type: 'NEW_PRICING' })
) & {
  leafCategory?: Pick<CategoryTreeFacetDistribution, 'name' | 'slug'>
}

export function useCreateVariantsRequest() {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: VariantRequestRequest[]) =>
      canaryClient.createVariantsRequest(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export function useCreateVariantsSearchDownloadMutation() {
  const canaryClient = useCanaryClient()
  return useMutation({
    mutationFn: ({
      categories,
      brands,
      minPrice,
      maxPrice,
      stockAvailability,
      cartAllocationQid,
      showWatchlistedOnly,
      recommendationsForGtin,
      hasDeals,
      query,
    }: GetVariantsSearchSearchParams) =>
      // Inconveniently, the payload for the search download is not exactly the same as for the regular search query
      // So we convert it here to avoid doing this all over the site
      canaryClient.createVariantsSearchDownload({
        brandNames: brands,
        minPrice: minPrice ? String(minPrice) : undefined,
        maxPrice: maxPrice ? String(maxPrice) : undefined,
        // The backend requires the categorySlug to be Array[] and not allow undefined. Fallback to [] allows the build to work
        categorySlug: categories || [],
        stockAvailability,
        cartAllocationQid,
        showWatchlistedOnly,
        recommendationsForGtin,
        hasDeals,
        query,
      }),
  })
}

import { ReactElement, ReactNode, SVGProps } from 'react'

type IconBaseProps = {
  path: ReactNode
} & IconProps

type IconProps = {
  title?: string
} & Pick<SVGProps<SVGElement>, 'className' | 'viewBox' | 'aria-hidden'>

type Icon = {
  (props: IconProps): ReactElement
  displayName: string
}

const IconBase = ({
  title,
  path,
  className,
  viewBox,
  'aria-hidden': ariaHidden,
}: IconBaseProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox={viewBox}
    width="24px"
    fill="#FFFFFF"
    role="img"
    aria-hidden={ariaHidden}
  >
    <>
      {title ? <title>{title}</title> : null}
      {path}
    </>
  </svg>
)

export const createIcon = (
  path: ReactNode,
  displayName: string,
  viewBox = '0 0 24 24',
): Icon => {
  const Icon = ({ title, className, 'aria-hidden': ariaHidden }: IconProps) => (
    <IconBase
      title={title}
      path={path}
      className={className}
      viewBox={viewBox}
      aria-hidden={ariaHidden}
    />
  )
  Icon.displayName = displayName
  return Icon
}

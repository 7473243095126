import { ChevronRightIcon } from '@heroicons/react/16/solid'
import { cn } from '@qogita/ui/utils/cn'
import Image from 'next/image'
import Link from 'next/link'
import { createContext, useContext, useState } from 'react'

import {
  deprecatedNavigationCategories,
  navigationCategories,
} from '#components/Masthead/TopLevelNavigation/constants.navigation'
import { useNewTaxonomyEnabled } from '#hooks/featureFlags'
import { useTrackEvent } from '#lib/report/tracking'
import { vercelImageLoader } from '#lib/url'

import { navigationBrands, NavigationCategory } from './constants.navigation'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from './NavigationMenu'

type DesktopNavigationContext = {
  activeMenu: string
  setActiveMenu: (menu: string) => void
}

const DesktopNavigationContext = createContext<
  DesktopNavigationContext | undefined
>(undefined)
function useDesktopNavigation() {
  const context = useContext(DesktopNavigationContext)
  if (context === undefined) {
    throw new Error(
      'DesktopNavigationContext compound components must be used within a DesktopNavigation',
    )
  }
  return context
}

const NavigationLink = ({
  href,
  children,
  className,
}: {
  href: string
  children: React.ReactNode
  className?: string
}) => {
  return (
    <Link legacyBehavior passHref href={href} shallow>
      <NavigationMenuLink
        className={cn(
          'focus:outline-primary-700 inline-block w-full rounded p-3 hover:bg-gray-100 focus:outline focus:outline-2',
          className,
        )}
      >
        {children}
      </NavigationMenuLink>
    </Link>
  )
}

const DesktopNavigationMenuContent = ({
  children,
  name,
}: {
  children: React.ReactNode
  name: string
}) => {
  const { activeMenu } = useDesktopNavigation()
  return (
    <NavigationMenuContent
      // These onPointer events are here to disable our the open on hover behavior
      // as suggested in https://github.com/radix-ui/primitives/issues/1630
      onPointerEnter={(event) => event.preventDefault()}
      onPointerMove={(event) => event.preventDefault()}
      onPointerLeave={(event) => event.preventDefault()}
      className={cn('max-w-auto grid-cols-5 gap-4 bg-white p-6', {
        grid: activeMenu === name,
        hidden: activeMenu !== name,
      })}
      forceMount
    >
      {children}
    </NavigationMenuContent>
  )
}

const NavigationTrigger = ({ children }: { children: React.ReactNode }) => {
  return (
    <NavigationMenuTrigger
      // These onPointer events are here to disable our the open on hover behavior
      // as suggested in https://github.com/radix-ui/primitives/issues/1630
      onPointerEnter={(event) => event.preventDefault()}
      onPointerMove={(event) => event.preventDefault()}
      onPointerLeave={(event) => event.preventDefault()}
      className="group/trigger flex h-auto rounded-none p-0 px-4 text-base font-bold capitalize"
    >
      <div className="py-3 group-hover/trigger:shadow-[inset_0_-1px_0_rgba(0,0,0,1)] group-focus/trigger:shadow-[inset_0_-1px_0_rgba(0,0,0,1)] group-data-[state=open]/trigger:shadow-[inset_0_-1px_0_rgba(0,0,0,1)]">
        {children}
      </div>
    </NavigationMenuTrigger>
  )
}

const NavListItem = ({
  slug,
  children,
}: {
  slug: string
  children: React.ReactNode
}) => {
  return (
    <li>
      <NavigationLink
        className="text-sm capitalize"
        href={`/categories/health-beauty/${slug}`}
      >
        {children}
      </NavigationLink>
    </li>
  )
}

const Lvl2List = ({ children }: { children: React.ReactNode }) => {
  return <ul className="col-span-4 grid grid-cols-4 gap-4">{children}</ul>
}

const Lvl3List = ({ children }: { children: React.ReactNode }) => {
  return <ul className="flex flex-col">{children}</ul>
}

const SubCategoryMenuItem = ({
  subCategory,
}: {
  subCategory: NavigationCategory['subCategories'][0]
}) => {
  return (
    <li>
      <div className="p-3 text-sm font-bold capitalize">{subCategory.name}</div>
      <Lvl3List>
        {subCategory.subCategories.map((subSubCategory) => (
          <NavListItem key={subSubCategory.slug} slug={subSubCategory.slug}>
            {subSubCategory.name}
          </NavListItem>
        ))}
        {subCategory.slug ? (
          <NavListItem slug={subCategory.slug}>
            <div className="flex items-center gap-1">
              <ChevronRightIcon className="h-4 w-4 shrink-0 text-gray-400" />
              All categories
            </div>
          </NavListItem>
        ) : null}
      </Lvl3List>
    </li>
  )
}

const CategoryMenuItem = ({
  name,
  slug,
  children,
}: {
  name: NavigationCategory['name']
  slug: NavigationCategory['slug']
  children?: React.ReactNode
}) => {
  return (
    <NavigationMenuItem value={name}>
      <NavigationTrigger>{name}</NavigationTrigger>
      <DesktopNavigationMenuContent name={name}>
        <div className="border-r pr-4">
          {slug ? (
            <NavigationLink
              className="text-base font-bold"
              href={`/categories/health-beauty/${slug}`}
            >
              All {name}
            </NavigationLink>
          ) : (
            <div className="p-3 text-base font-bold">{name}</div>
          )}
        </div>
        {children}
      </DesktopNavigationMenuContent>
    </NavigationMenuItem>
  )
}

export const DesktopNavigation = () => {
  const { trackNavigationMenuOpened } = useTrackEvent()
  const [activeMenu, setActiveMenu] = useState('')
  const newTaxonomyEnabled = useNewTaxonomyEnabled()

  return (
    <DesktopNavigationContext.Provider value={{ activeMenu, setActiveMenu }}>
      <div className="flex w-full flex-row justify-center border-b border-gray-900">
        <NavigationMenu
          onValueChange={(value) => {
            if (value) {
              trackNavigationMenuOpened({
                menuType: value,
                taxonomy: newTaxonomyEnabled ? 'v2' : 'v1',
              })
            }
            setActiveMenu(value)
          }}
          className="max-w-site-content z-20"
        >
          <NavigationMenuList>
            <NavigationMenuItem value="Brands">
              <NavigationTrigger>Brands</NavigationTrigger>
              <NavigationMenuContent
                className={cn('grid-cols-5 gap-4 bg-white p-6', {
                  grid: activeMenu === 'Brands',
                  hidden: activeMenu !== 'Brands',
                })}
                forceMount
              >
                <div className="border-r pr-4">
                  <NavigationLink
                    className="text-base font-bold"
                    href={`/brands`}
                  >
                    Premium Brands A-Z
                  </NavigationLink>
                </div>
                <ul className="col-span-4 grid grid-cols-5 gap-4">
                  {navigationBrands.map((brand) => (
                    <li key={brand.name}>
                      <Link
                        legacyBehavior
                        passHref
                        href={{
                          pathname: '/brands/[brand]',
                          query: { brand: brand.slug },
                        }}
                      >
                        <NavigationMenuLink>
                          <Image
                            className="mb-2 rounded p-2 py-6 shadow"
                            loader={vercelImageLoader}
                            src={brand.logo}
                            alt={brand.name}
                          />
                          <div className="text-sm font-medium">
                            {brand.name}
                          </div>
                        </NavigationMenuLink>
                      </Link>
                    </li>
                  ))}
                </ul>
              </NavigationMenuContent>
            </NavigationMenuItem>
            {newTaxonomyEnabled ? (
              <>
                <CategoryMenuItem
                  name={navigationCategories.fragrance.name}
                  slug={navigationCategories.fragrance.slug}
                >
                  <Lvl2List>
                    {navigationCategories.fragrance.subCategories.map(
                      (subCategory, index) => (
                        <SubCategoryMenuItem
                          key={`${subCategory?.slug}-${index}`}
                          subCategory={subCategory}
                        />
                      ),
                    )}
                  </Lvl2List>
                </CategoryMenuItem>

                <CategoryMenuItem
                  name={navigationCategories.makeup.name}
                  slug={navigationCategories.makeup.slug}
                >
                  <Lvl2List>
                    {navigationCategories.makeup.subCategories.map(
                      (subCategory, index) => (
                        <SubCategoryMenuItem
                          key={`${subCategory?.slug}-${index}`}
                          subCategory={subCategory}
                        />
                      ),
                    )}
                  </Lvl2List>
                </CategoryMenuItem>

                <CategoryMenuItem
                  name={navigationCategories.hair.name}
                  slug={navigationCategories.hair.slug}
                >
                  <Lvl2List>
                    {navigationCategories.hair.subCategories.map(
                      (subCategory, index) => (
                        <SubCategoryMenuItem
                          key={`${subCategory?.slug}-${index}`}
                          subCategory={subCategory}
                        />
                      ),
                    )}
                  </Lvl2List>
                </CategoryMenuItem>

                <CategoryMenuItem
                  name={navigationCategories.face.name}
                  slug={navigationCategories.face.slug}
                >
                  <Lvl2List>
                    {navigationCategories.face.subCategories.map(
                      (subCategory, index) => (
                        <SubCategoryMenuItem
                          key={`${subCategory?.slug}-${index}`}
                          subCategory={subCategory}
                        />
                      ),
                    )}
                  </Lvl2List>
                </CategoryMenuItem>

                <CategoryMenuItem
                  name={navigationCategories.body.name}
                  slug={navigationCategories.body.slug}
                >
                  <Lvl2List>
                    {navigationCategories.body.subCategories.map(
                      (subCategory, index) => (
                        <SubCategoryMenuItem
                          key={`${subCategory?.slug}-${index}`}
                          subCategory={subCategory}
                        />
                      ),
                    )}
                  </Lvl2List>
                </CategoryMenuItem>

                <CategoryMenuItem
                  name={navigationCategories.health.name}
                  slug={navigationCategories.health.slug}
                >
                  <Lvl2List>
                    {navigationCategories.health.subCategories.map(
                      (subCategory, index) => (
                        <SubCategoryMenuItem
                          key={`${subCategory?.slug}-${index}`}
                          subCategory={subCategory}
                        />
                      ),
                    )}
                  </Lvl2List>
                </CategoryMenuItem>

                <CategoryMenuItem
                  name={navigationCategories.homeLifestyle.name}
                  slug={navigationCategories.homeLifestyle.slug}
                >
                  <Lvl2List>
                    {navigationCategories.homeLifestyle.subCategories.map(
                      (subCategory, index) => (
                        <SubCategoryMenuItem
                          key={`${subCategory?.slug}-${index}`}
                          subCategory={subCategory}
                        />
                      ),
                    )}
                  </Lvl2List>
                </CategoryMenuItem>
              </>
            ) : (
              <>
                <CategoryMenuItem
                  name={deprecatedNavigationCategories.fragrance.name}
                  slug={deprecatedNavigationCategories.fragrance.slug}
                >
                  <Lvl2List>
                    {deprecatedNavigationCategories.fragrance.subCategories.map(
                      (subCategory, index) => (
                        <SubCategoryMenuItem
                          key={`${subCategory?.slug}-${index}`}
                          subCategory={subCategory}
                        />
                      ),
                    )}
                  </Lvl2List>
                </CategoryMenuItem>

                <CategoryMenuItem
                  name={deprecatedNavigationCategories.makeup.name}
                  slug={deprecatedNavigationCategories.makeup.slug}
                >
                  <Lvl2List>
                    {deprecatedNavigationCategories.makeup.subCategories.map(
                      (subCategory, index) => (
                        <SubCategoryMenuItem
                          key={`${subCategory?.slug}-${index}`}
                          subCategory={subCategory}
                        />
                      ),
                    )}
                  </Lvl2List>
                </CategoryMenuItem>

                <CategoryMenuItem
                  name={deprecatedNavigationCategories.hair.name}
                  slug={deprecatedNavigationCategories.hair.slug}
                >
                  <Lvl2List>
                    {deprecatedNavigationCategories.hair.subCategories.map(
                      (subCategory, index) => (
                        <SubCategoryMenuItem
                          key={`${subCategory?.slug}-${index}`}
                          subCategory={subCategory}
                        />
                      ),
                    )}
                  </Lvl2List>
                </CategoryMenuItem>

                <CategoryMenuItem
                  name={deprecatedNavigationCategories.skincare.name}
                  slug={deprecatedNavigationCategories.skincare.slug}
                >
                  <Lvl2List>
                    {deprecatedNavigationCategories.skincare.subCategories.map(
                      (subCategory, index) => (
                        <SubCategoryMenuItem
                          key={`${subCategory?.slug}-${index}`}
                          subCategory={subCategory}
                        />
                      ),
                    )}
                  </Lvl2List>
                </CategoryMenuItem>

                <CategoryMenuItem
                  name={deprecatedNavigationCategories.bathShower.name}
                  slug={deprecatedNavigationCategories.bathShower.slug}
                >
                  <Lvl2List>
                    {deprecatedNavigationCategories.bathShower.subCategories.map(
                      (subCategory, index) => (
                        <SubCategoryMenuItem
                          key={`${subCategory?.slug}-${index}`}
                          subCategory={subCategory}
                        />
                      ),
                    )}
                  </Lvl2List>
                </CategoryMenuItem>

                <CategoryMenuItem
                  name={deprecatedNavigationCategories.nailCare.name}
                  slug={deprecatedNavigationCategories.nailCare.slug}
                >
                  <Lvl2List>
                    {deprecatedNavigationCategories.nailCare.subCategories.map(
                      (subCategory, index) => (
                        <SubCategoryMenuItem
                          key={`${subCategory?.slug}-${index}`}
                          subCategory={subCategory}
                        />
                      ),
                    )}
                  </Lvl2List>
                </CategoryMenuItem>

                <CategoryMenuItem
                  name={deprecatedNavigationCategories.shavingGrooming.name}
                  slug={deprecatedNavigationCategories.shavingGrooming.slug}
                >
                  <Lvl2List>
                    {deprecatedNavigationCategories.shavingGrooming.subCategories.map(
                      (subCategory, index) => (
                        <SubCategoryMenuItem
                          key={`${subCategory?.slug}-${index}`}
                          subCategory={subCategory}
                        />
                      ),
                    )}
                  </Lvl2List>
                </CategoryMenuItem>
              </>
            )}
          </NavigationMenuList>
        </NavigationMenu>
      </div>
    </DesktopNavigationContext.Provider>
  )
}

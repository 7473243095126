import type { BuyerCurrency, Country } from '@qogita/canary-types'

/**
 * @description map to return currency based on country code
 * @param countryCode
 * @returns currency string
 */
export const getCurrencyFromCountryCode = (
  countryCode: Country,
): BuyerCurrency => {
  switch (countryCode) {
    case 'GB':
      return 'GBP'
    case 'US':
      return 'USD'
    default:
      return 'EUR'
  }
}

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { Bars2Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Drawer } from '@qogita/ui/drawer'
import Image from 'next/image'
import Link from 'next/link'
import { createContext, useContext, useState } from 'react'

import { useNewTaxonomyEnabled } from '#hooks/featureFlags'
import { vercelImageLoader } from '#lib/url'

import {
  deprecatedNavigationCategories,
  navigationBrands,
  navigationCategories,
  NavigationSubCategory,
} from './constants.navigation'

type NavigationArrayItem = NavigationSubCategory['name'] | 'Brands'

type GetCurrentCategoryData = (
  selectedCategoryArray: NavigationSubCategory['name'][],
  navigationCategories: NavigationSubCategory[],
) => NavigationSubCategory | undefined

const getCurrentCategoryData: GetCurrentCategoryData = (
  navigationArray,
  navigationCategories,
) => {
  const selectedCategoryArray = [...navigationArray]
  selectedCategoryArray.shift()
  let categoryList = navigationCategories
  let currentCategory
  for (const selectedCategory of selectedCategoryArray) {
    currentCategory = categoryList.find(
      (category) => category.name == selectedCategory,
    )
    categoryList = currentCategory?.subCategories || []
  }
  return currentCategory
}

type MobileNavigationContext = {
  isOpen: boolean
  open: () => void
  close: () => void
  navigationArray: NavigationArrayItem[]
  navigateForward: (navigationItem: NavigationArrayItem) => void
  navigateBack: () => void
}

const MobileNavigationContext = createContext<
  MobileNavigationContext | undefined
>(undefined)
function useMobileNavigation() {
  const context = useContext(MobileNavigationContext)
  if (context === undefined) {
    throw new Error(
      'MobileNavigationContext compound components must be used within a MobileNavigation',
    )
  }
  return context
}

const NavigationHeader = () => {
  const { navigationArray, navigateBack } = useMobileNavigation()
  const showBackButton = navigationArray.length > 0
  return (
    <div className="flex w-full justify-between border-b border-gray-200">
      <div className="p-4">
        <div className="flex gap-2">
          {showBackButton ? (
            <button onClick={navigateBack}>
              <ChevronLeftIcon
                className="w-6 text-gray-400"
                aria-label={`back to the ${
                  navigationArray.length > 1
                    ? navigationArray.at(-2)
                    : 'categories'
                } menu`}
              />
            </button>
          ) : null}
          <h2 className="text-base font-bold">
            {navigationArray.length > 0
              ? navigationArray.at(-1)
              : 'Browse Catalog'}
          </h2>
        </div>
      </div>
      <Drawer.CloseButton className="p-4">
        <XMarkIcon className="w-6 text-gray-400" />
      </Drawer.CloseButton>
    </div>
  )
}

const BrandsMenu = () => {
  const { close } = useMobileNavigation()

  return (
    <div className="mt-4">
      <Link href="/brands" className="px-4 py-3 text-sm font-bold">
        Shop Premium Brands A-Z
      </Link>
      <ul className="my-4 grid auto-rows-[1fr] grid-cols-2 gap-x-2 gap-y-4 px-4">
        {navigationBrands.map((brand) => (
          <li key={brand.name}>
            <Link
              href={{
                pathname: '/brands/[brand]',
                query: {
                  brand: brand.slug,
                },
              }}
              onClick={close}
            >
              <div className="flex h-full flex-col gap-2">
                <div className="flex grow items-center rounded p-2 shadow">
                  <Image
                    loader={vercelImageLoader}
                    src={brand.logo}
                    alt={brand.name}
                  />
                </div>
                <h3 className="text-sm font-medium">{brand.name}</h3>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

const CategoriesMenu = () => {
  const { navigationArray, close, navigateForward } = useMobileNavigation()
  const newTaxonomyEnabled = useNewTaxonomyEnabled()

  const topLevelCategories = newTaxonomyEnabled
    ? Object.values(navigationCategories)
    : Object.values(deprecatedNavigationCategories)

  const currentCategory = getCurrentCategoryData(
    navigationArray,
    topLevelCategories,
  )
  const categoryList = currentCategory?.subCategories || topLevelCategories

  return (
    <ul className="my-4">
      {currentCategory?.slug ? (
        <li className="px-4 py-2">
          <Link
            href={`/categories/health-beauty/${currentCategory.slug}`}
            onClick={close}
            className="text-sm font-bold"
            shallow
          >
            Shop all {currentCategory.name}
          </Link>
        </li>
      ) : null}
      {categoryList.map((category) => {
        return (
          <li key={category.name} className="px-4 py-3">
            {category.subCategories && category.subCategories.length > 0 ? (
              <button
                onClick={() => {
                  navigateForward(category.name)
                }}
                className="flex w-full items-center justify-between"
              >
                <span className="text-sm">{category.name}</span>
                <ChevronRightIcon className="w-6 text-gray-400" />
              </button>
            ) : (
              <Link
                href={`/categories/health-beauty/${category.slug}`}
                onClick={close}
                className="block text-sm"
                shallow
              >
                {category.name}
              </Link>
            )}
          </li>
        )
      })}
    </ul>
  )
}

const MenuContent = () => {
  const { navigationArray, navigateForward } = useMobileNavigation()

  if (navigationArray[0] === 'Brands') {
    return <BrandsMenu />
  }

  if (navigationArray[0] === 'Categories') {
    return <CategoriesMenu />
  }

  return (
    <ul>
      <li className="px-4 py-3">
        <button
          onClick={() => {
            navigateForward('Categories')
          }}
          className="flex w-full items-center justify-between"
        >
          <span className="text-sm font-bold">Categories</span>
          <ChevronRightIcon className="w-6 text-gray-400" />
        </button>
      </li>
      <li className="px-4 py-2">
        <button
          onClick={() => {
            navigateForward('Brands')
          }}
          className="flex w-full items-center justify-between"
        >
          <span className="text-sm font-bold">Brands</span>
          <ChevronRightIcon className="w-6 text-gray-400" />
        </button>
      </li>
    </ul>
  )
}

const MobileNavigationDrawer = () => {
  const { isOpen, close } = useMobileNavigation()

  return (
    <Drawer isOpen={isOpen} onClose={close} align="left">
      <Drawer.Content className="max-w-full">
        {isOpen ? (
          <div className="flex w-full flex-col">
            <div>
              <NavigationHeader />
              <div className="h-dvh overflow-auto pb-16">
                <MenuContent />
              </div>
            </div>
          </div>
        ) : null}
      </Drawer.Content>
    </Drawer>
  )
}

const MobileNavigationTrigger = () => {
  const { open } = useMobileNavigation()
  return (
    <div className="flex h-full flex-1">
      <button onClick={open}>
        <span className="sr-only">Categories menu</span>
        <Bars2Icon className="w-6" />
      </button>
    </div>
  )
}

export const MobileNavigation = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [navigationArray, setNavigationArray] = useState<NavigationArrayItem[]>(
    [],
  )

  const navigateForward = (navigationItem: NavigationArrayItem) => {
    setNavigationArray([...navigationArray, navigationItem])
  }

  const navigateBack = () => {
    setNavigationArray(navigationArray.slice(0, -1))
  }

  const open = () => setIsOpen(true)

  const close = () => {
    setIsOpen(false)
    setNavigationArray([])
  }

  return (
    <MobileNavigationContext.Provider
      value={{
        isOpen,
        open,
        close,
        navigationArray,
        navigateForward,
        navigateBack,
      }}
    >
      <MobileNavigationTrigger />
      <MobileNavigationDrawer />
    </MobileNavigationContext.Provider>
  )
}

import { logError } from '@qogita/logging/browser-logger'
import Script from 'next/script'

import { useConsent } from '#contexts/Consent'
import { environment } from '#lib/environment.mjs'

export const BingScript = () => {
  const { consent } = useConsent()

  const isConsentAccepted =
    consent.status !== 'loading' && consent.value.marketing

  return isConsentAccepted ? (
    <Script id="bing-tag">
      {`(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"${environment.NEXT_PUBLIC_BING_TAG_ID}", enableAutoSpaTracking: true};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`}
    </Script>
  ) : null
}

export const bingEvent = (
  eventName: string,
  eventParams?: Record<string, unknown>,
) => {
  if (!window.uetq) return

  try {
    window.uetq.push('event', eventName, eventParams ?? {})
  } catch (error) {
    logError(error)
  }
}

import { LoadingIcon } from '@qogita/ui/loading-icon'
import { cn } from '@qogita/ui/utils/cn'

type LoaderProps = {
  className?: string
}
export const Loader = ({ className }: LoaderProps) => {
  return (
    <div
      className={cn(
        'flex items-center justify-center text-gray-400',
        className,
      )}
    >
      <LoadingIcon />
    </div>
  )
}

import { cn } from '@qogita/ui/utils/cn'
import { storyblokEditable } from '@storyblok/react'

import { HeaderStoryblok } from '#types/storyblok-component-types'

import { renderRichText } from './RichText'

export function Header({
  blok,
  className,
}: {
  blok: HeaderStoryblok
  className?: string
}) {
  return (
    <div
      {...storyblokEditable(blok)}
      className={cn('flex flex-col gap-8', className)}
    >
      <div className="flex flex-col gap-2">
        {blok.label ? (
          <p className="text-primary-700 text-balance text-lg font-bold">
            {blok.label}
          </p>
        ) : null}
        <h2 className="text-balance text-4xl font-bold">{blok.heading}</h2>
      </div>
      <div className="flex flex-col gap-4 whitespace-pre-wrap text-pretty">
        {blok.description ? renderRichText(blok.description) : null}
      </div>
    </div>
  )
}

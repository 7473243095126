import { logWarning } from '@qogita/logging/browser-logger'
import { storyblokEditable } from '@storyblok/react'
import Image from 'next/image'
import { StoryblokStory } from 'storyblok-generate-ts'

import { CenteredContent } from '#components/Marketing/CenteredContent'
import {
  TestimonialSimplePanelStoryblok,
  TestimonialStoryblok,
} from '#types/storyblok-component-types'

import { storyblokImageLoader } from './storyblokImageLoader'

export function TestimonialSimplePanel({
  blok,
}: {
  blok: TestimonialSimplePanelStoryblok
}) {
  return (
    <div {...storyblokEditable(blok)} className="bg-gray-50 py-12">
      <CenteredContent>
        <div className="flex flex-col items-center gap-6">
          {blok.logo?.filename ? (
            <Image
              loader={storyblokImageLoader}
              src={blok.logo.filename}
              alt={blok.logo.alt ?? ''}
              width={256}
              height={256}
              className="h-12 w-auto"
            />
          ) : null}
          <Testimonial blok={blok.testimonial} />
        </div>
      </CenteredContent>
    </div>
  )
}

function Testimonial({
  blok,
}: {
  blok: StoryblokStory<TestimonialStoryblok> | string
}) {
  if (typeof blok === 'string') {
    // We weren't able to resolve the testimonial story, maybe we hit the resolver limit (50 resolutions)
    logWarning(`Storyblok testimonial not resolved: ${blok}`)
    return null
  }

  const { quote, name, title, avatar } = blok.content

  return (
    <div className="flex max-w-prose flex-col items-center gap-6 text-pretty text-center">
      <blockquote className="text-lg font-light">
        &ldquo;{quote}&rdquo;
      </blockquote>
      <div className="flex flex-col items-center gap-2">
        {avatar ? (
          <Image
            loader={storyblokImageLoader}
            src={avatar.filename}
            alt={avatar.alt ?? ''}
            width={40}
            height={40}
            className="rounded-full"
          />
        ) : null}
        <figcaption>
          <p className="font-bold">{name}</p>
          {title ? <p className="text-gray-700">{title}</p> : null}
        </figcaption>
      </div>
    </div>
  )
}

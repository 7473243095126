import { cn } from '@qogita/ui/utils/cn'
import { forwardRef, HTMLProps } from 'react'

/**
 * Applies the appropriate padding and margin for application-width content in the center of the page
 * Use this inside something like a full-bleed background color to get the correct fit of the content
 */
export const CenteredContent = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement>
>(function CenteredContent({ children, className, ...props }, ref) {
  return (
    <div
      ref={ref}
      className={cn(
        'max-w-site-content mx-auto w-full px-4 md:px-6',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
})
CenteredContent.displayName = 'CenteredContent'

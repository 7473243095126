import { useFlags } from 'launchdarkly-react-client-sdk'

// This is an example usage of the useFlags hook
// Keeping it here so we don't end up with a disallowed empty file when we don't currently have
// any flags in use
// ts-unused-exports:disable-next-line
export const useExampleFlag = () => {
  const flags = useFlags()

  return flags.exampleFlag === true
}

export const useOptimizerIncreaseQuantityForUnitConstraints = () => {
  const flags = useFlags()

  return flags.optimizerIncreaseQuantityForUnitConstraints === true
}

export const useCatalogIsWatchlistedFilterEnabled = () => {
  const flags = useFlags()

  return flags.catalogIsWatchlistedFilter === true
}

export const useIsPdpShippingFromCountryFlagVisible = () => {
  const flags = useFlags()

  return flags.frontendBuyersPdpShowShippingFromCountryFlag === true
}

export const useNewTaxonomyEnabled = () => {
  const flags = useFlags()

  return flags.newTaxonomyEnabled === true
}

export const useClaimEvidencePerLineEnabled = () => {
  const flags = useFlags()

  return flags.claimEvidencePerLine === true
}

export const useNewPricingModelEnabled = () => {
  const flags = useFlags()

  return flags.enableOffersSearch === true
}

import { createContext, ReactNode, useContext } from 'react'

type ServerAuthProps =
  | { isAuthenticated: false }
  | { isAuthenticated: true; signature: string }

type AuthenticationContext = ServerAuthProps

const AuthenticationContext = createContext<AuthenticationContext | undefined>(
  undefined,
)

const AuthenticationProvider = ({
  children,
  ...serverAuthProps
}: { children: ReactNode } & ServerAuthProps) => {
  return (
    <AuthenticationContext.Provider value={{ ...serverAuthProps }}>
      {children}
    </AuthenticationContext.Provider>
  )
}

const useAuthentication = (): AuthenticationContext => {
  const context = useContext(AuthenticationContext)
  if (context === undefined) {
    throw new Error(
      'useAuthentication must be used within an AuthenticationProvider.',
    )
  }
  return context
}

export { AuthenticationProvider, useAuthentication }
